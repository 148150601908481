import { useState } from 'react';
import { Link } from 'react-router-dom';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LearnerLeadNeedHelpModal from 'components/section/Modals/LearnerLeadNeedHelpModal';

import imgNoSchoolsFound from 'assets/driving_school/no_schools_found.svg';

import './listing-not-found.css';

function ListingNotFound() {
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalToggle = () => {
    setModalVisible(!modalVisible);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      {/* Listing Not Found Content */}
      <div className="container mb-5">
        <div className="row d-flex justify-content-center align-items-center listing-not-found">
          <img src={imgNoSchoolsFound} alt="No Schools Found" className="img-fluid" />
          <p className="fw-normal fs-4 text-center">We are soon coming to your area</p>
          <button type="button" onClick={handleModalToggle} className=" fs-5">
            <FontAwesomeIcon icon={faSearch} /> Help us find you
          </button>
        </div>
      </div>

      {/* Listing not found banner */}
      <div className="container-fluid listing-not-found__banner py-3">
        <div className="container">
          <div className="row d-flex flex-row align-items-center">
            <div className="col-12 col-sm-9 col-lg-10">
              <div className="d-flex flex-wrap align-items-baseline justify-content-start">
                <p className="fs-5 fw-normal me-2">Get familiar with the regulations of the road</p>
                <p className="fw-light listing-not-found__banner_subtext">
                  Avoiding chalans will be easier with this information
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-3 col-lg-2">
              <div className="d-flex justify-content-center">
                <Link to="/blogs">
                  <button type="button" className="d-flex rounded-3 py-2 px-4 border-0">
                    Learn now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {modalVisible && <LearnerLeadNeedHelpModal onClose={handleModalClose} />}
    </>
  );
}

export default ListingNotFound;
