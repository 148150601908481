import { PropsWithChildren } from 'react';
import { toast } from 'react-toastify';

import Dependencies from 'Dependencies';
import { useFormik } from 'formik';
import drivingSchoolLeadFormValidationSchema from 'schemas/validations/driving-school-lead';

import { ApiError } from 'domain/ApiErrorResponse';
import { DrivingSchoolLeadModel } from 'domain/DrivingSchoolLeadModel';

import imageFormBg from 'assets/partner_with_us/form-bg-element.svg';
import iconName from 'assets/partner_with_us/icon_input_name.svg';
import iconPhone from 'assets/partner_with_us/icon_input_phone.svg';

import './index.css';

const initialValues = {
  drivingSchoolName: '',
  mobileNumber: '',
};

type DrivingSchoolLeadFormProps = {
  onSuccess(): void;
};

function DrivingSchoolLeadForm({ onSuccess }: PropsWithChildren<DrivingSchoolLeadFormProps>) {
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: drivingSchoolLeadFormValidationSchema,
    onSubmit: (value, action) => {
      const payload: DrivingSchoolLeadModel = {
        id: null,
        data: { name: value.drivingSchoolName ?? '', mobile_number: value.mobileNumber ?? '' },
      };

      async function createNewDSLead() {
        try {
          await toast.promise(Dependencies.drivingSchoolRepo.createNewDrivingSchoolLead(payload), {
            pending: 'Sending Your Request!',
            success: {
              render() {
                return 'Thank you for your Interest, we will get back to you ASAP';
              },
            },
            error: {
              render({ data }) {
                const apiError = data as ApiError;
                if (apiError) {
                  const errorMessage = apiError?.response?.data?.error?.message;
                  if (
                    apiError.response?.status === 400 &&
                    errorMessage === 'This attribute must be unique'
                  )
                    return 'This mobile number is already registered!';
                  return errorMessage;
                }
                return 'Oops, Something went wrong!';
              },
            },
          });
          onSuccess();
          action.resetForm();
        } catch (error) {
          const apiError = error as ApiError;
          if (apiError) {
            const errorMessage = apiError?.response?.data?.error?.message;
            if (
              apiError.response?.status === 400 &&
              errorMessage === 'This attribute must be unique'
            )
              toast.error('This mobile number is already registered!', { autoClose: 5000 });
            else toast.error(errorMessage);
          } else toast.error('Oops, Something went wrong!');
        }
      }
      createNewDSLead();
    },
  });

  return (
    <div className="card ds-lead-form__card px-3">
      <div className="position-relative">
        <img
          src={imageFormBg}
          alt="Top Left"
          className="position-absolute top-0 start-0 translate-middle non_selectable"
        />
      </div>
      <h3 className="card-title fw-semibold ds-lead-form__title text-center mt-5">
        अपनी online presence बढ़ाएँ !
      </h3>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <div className="input-group mb-3 has-validation">
              <span className="input-group-text input-icon border-end-0 rounded-0 rounded-start">
                <img src={iconName} alt="name" />
              </span>
              <input
                className="form-control border-start-0 ms-0 ps-0 rounded-0 rounded-end"
                placeholder="Enter name of your driving school"
                name="drivingSchoolName"
                value={values.drivingSchoolName}
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.drivingSchoolName && touched.drivingSchoolName ? (
                <div className="invalid-feedback d-block">{errors.drivingSchoolName}</div>
              ) : null}
            </div>
          </div>
          <div className="input-group">
            <div className="input-group mb-3 has-validation">
              <span className="input-group-text input-icon border-end-0 rounded-0 rounded-start">
                <img src={iconPhone} alt="mobile" />
              </span>
              <input
                type="text"
                placeholder="Enter mobile number"
                name="mobileNumber"
                value={values.mobileNumber}
                className="form-control border-start-0 ms-0 ps-0 rounded-0 rounded-end"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.mobileNumber && touched.mobileNumber ? (
                <div className="invalid-feedback d-block">{errors.mobileNumber}</div>
              ) : null}
            </div>
          </div>
          <div className="d-grid gap-2">
            <button type="submit" className="primary-button fs-5 fw-normal py-2 mt-3 mb-4">
              Advertise Now
            </button>
          </div>
        </form>
      </div>
      {/* <div className="d-flex flex-column justify-content-center">
        <div className="row p-4"></div>
      </div> */}
      <div className="position-relative">
        <img
          src={imageFormBg}
          className="position-absolute top-0 start-100 translate-middle non_selectable"
          alt="Bottom Right"
        />
      </div>
    </div>
  );
}

export default DrivingSchoolLeadForm;
