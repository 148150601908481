import * as Yup from 'yup';

const modal1 = Yup.object({
  name: Yup.string()
    .min(2, 'Name must be more than 2 character')
    .max(100, 'Name must be less than 100 character')
    .required('Please enter your name'),
  gender: Yup.string().required('Please select an option'),
  age: Yup.number()
    .min(12, 'You seem to be underaged')
    .max(120, 'Are you alive?')
    .required('Can not be empty'),
  number: Yup.string()
    .min(8, 'Phone number be more than 8 digits')
    .max(13, 'Phone number must be less than 13 digits')
    .required('Please provide us your contact details')
    .matches(/^(\+)?\d+$/, 'Please enter a valid phone number'),
});
export default modal1;
