import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Dependencies from 'Dependencies';

import { ApiError } from 'domain/ApiErrorResponse';

import imgRatingStar from 'assets/profile/icon_rating_star.svg';
import imgRatingStarFill from 'assets/profile/icon_rating_star_fill.svg';

import './rating-card.css';

// import { toast } from 'react-toastify';

type RatingProp = {
  onClose: () => void;
  dSchoolId: number;
};

function Rating({ onClose, dSchoolId }: RatingProp) {
  const [rating, setRating] = useState(0);

  const handleStarClick = (selectedRating: number) => {
    setRating(selectedRating);
    async function setDSRating() {
      try {
        await Dependencies.drivingSchoolRepo.setDrivingSchoolRating(dSchoolId, selectedRating);
        toast.success('Thank You For Rating this driving School!');
        onClose();
      } catch (error) {
        const apiError = error as ApiError;
        if (apiError) {
          const errorMessage = apiError?.response?.data?.error?.message;
          toast.error(errorMessage);
        }
      }
    }
    setDSRating();
  };

  return (
    <div className="rating_card-background">
      <Modal.Body>
        <Button
          variant="secondary"
          onClick={onClose}
          className="btn-close float-start ps-4 pt-4"
          aria-label="Close"
        />
      </Modal.Body>
      <div className="d-flex justify-content-center align-items-center h-100 flex-column">
        <p className="fs-5 fw-semibold mt-1">Rate this driving school</p>
        <div className="d-flex mb-3">
          {[1, 2, 3, 4, 5].map((index) => (
            <button
              type="button"
              key={index}
              className={`rating_button-background ${rating >= index ? 'filled' : ''}`}
              onClick={() => handleStarClick(index)}
            >
              <img
                src={rating >= index ? imgRatingStarFill : imgRatingStar}
                alt={`Rating Star - ${index}`}
                style={{ height: '1.5rem' }}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Rating;
