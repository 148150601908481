import { ContactUsLeadModel } from 'domain/ContactUsLeadModel';

import ContactUsLeadDataSource from 'data/datasource/ContactUsLeadDataSource';

import ContactUsLeadRepository from './ContactUsLeadRepository';

class ContactUsLeadRepositoryImpl implements ContactUsLeadRepository {
  dataSource: ContactUsLeadDataSource;

  constructor(_dataSource: ContactUsLeadDataSource) {
    this.dataSource = _dataSource;
  }

  createNewLead(data: ContactUsLeadModel): Promise<boolean> {
    return this.dataSource.createNewLead(data);
  }
}

export default ContactUsLeadRepositoryImpl;
