import { useState } from 'react';
import { Button, FormCheck, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Dependencies from 'Dependencies';
import { useFormik } from 'formik';
import modal from 'schemas/validations/learner-lead-no-school';

import { ApiError } from 'domain/ApiErrorResponse';
import { LearnerLeadModel, LearnerLeadType } from 'domain/LearnerLeadModel';

import imageThankYou from 'assets/listing/icon_thank_you.svg';
import imageFormBg from 'assets/partner_with_us/form-bg-element.svg';
import iconAge from 'assets/partner_with_us/icon_input_age.svg';
import iconName from 'assets/partner_with_us/icon_input_name.svg';
import iconPincode from 'assets/partner_with_us/icon_input_pincode.svg';
import iconWhatsapp from 'assets/partner_with_us/icon_input_whatsapp.svg';

import './Modal.css';

const initialValues = {
  name: '',
  mobile_number: '',
  gender: 'na',
  age: undefined,
  dschool_reachable: false,
  pincode: undefined,
  driving_school: 0,
};

interface LearnerLeadNeedHelpModalProps {
  onClose: () => void;
}

function LearnerLeadNeedHelpModal({ onClose }: LearnerLeadNeedHelpModalProps) {
  const [showModal, setShowModal] = useState(true); // State variable to track modal visibility
  const [response, setResponse] = useState(false);
  const [modalview, setModalview] = useState(true);

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema: modal,
    onSubmit: () => {
      async function createNewLearnerLead() {
        const lead: LearnerLeadModel = {
          data: {
            name: values.name ?? '',
            mobile_number: values.mobile_number ?? '',
            type: LearnerLeadType.needs_help,
            gender: values.gender || 'na',
            age: values.age || 12,
            pincode: values.pincode,
            dschool_reachable: null,
            driving_school: null,
            driving_school_course: null,
          },
        };

        try {
          await Dependencies.learnerLeadRepo.createNewLearnerLead(lead);
          setModalview(false);
          setResponse(true); // Close the modal
          setTimeout(() => {
            setShowModal(false);
            onClose();
          }, 5000);
        } catch (error) {
          const apiError = error as ApiError;
          if (apiError) {
            const errorMessage = apiError?.response?.data?.error?.message;
            if (
              apiError?.response?.status === 400 &&
              errorMessage === 'This attribute must be unique'
            ) {
              toast.error('This mobile number is already registered!', { autoClose: 5000 });
            } else {
              toast.error(errorMessage);
            }
          } else {
            toast.error('Oops, Something went wrong!');
          }
        }
      }

      createNewLearnerLead();
    },
  });

  const handleClose = () => {
    setShowModal(false); // Close the modal
    onClose(); // Call the onClose function passed as a prop
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered className="d-flex justify-content-center">
      <div className="overflow-hidden modal-background rounded-3 w-auto">
        <div className="position-relative">
          <img
            src={imageFormBg}
            alt="Top Left"
            className="position-absolute top-0 start-0 translate-middle non_selectable"
          />
        </div>
        {modalview && (
          <Modal.Body>
            <Button
              variant="secondary"
              onClick={handleClose}
              className="btn-close float-end"
              aria-label="Close"
            />
            <p className="modalHeading d-flex justify-content-center fw-bold">
              Where can we reach you?
            </p>
            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <div className="input-group mb-3 has-validation">
                  <span className="input-group-text input-icon border-end-0 rounded-0 rounded-start">
                    <img src={iconName} alt="name" />
                  </span>
                  <input
                    className="form-control border-start-0 ms-0 ps-0 rounded-0 rounded-end"
                    placeholder="Enter your name"
                    name="name"
                    value={values.name}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.name && touched.name ? (
                    <div className="invalid-feedback d-block">{errors.name}</div>
                  ) : null}
                </div>
              </div>
              <div className="input-group">
                <div className="input-group mb-3 has-validation">
                  <span className="input-group-text input-icon border-end-0 rounded-0 rounded-start">
                    <img src={iconWhatsapp} alt="whatsapp" />
                  </span>
                  <input
                    type="text"
                    placeholder="whatsapp number"
                    name="mobile_number"
                    value={values.mobile_number}
                    className="form-control border-start-0 ms-0 ps-0 rounded-0 rounded-end"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.mobile_number && touched.mobile_number ? (
                    <div className="invalid-feedback d-block">{errors.mobile_number}</div>
                  ) : null}
                </div>
              </div>
              <div className="input-group">
                <div className="input-group mb-3 has-validation">
                  <span className="input-group-text input-icon border-end-0 rounded-0 rounded-start">
                    <img src={iconPincode} alt="pincode" />
                  </span>
                  <input
                    type="text"
                    placeholder="Pincode"
                    name="pincode"
                    value={values.pincode}
                    className="form-control border-start-0 ms-0 ps-0 rounded-0 rounded-end"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.pincode && touched.pincode ? (
                    <div className="invalid-feedback d-block">{errors.pincode}</div>
                  ) : null}
                </div>
              </div>
              <div className="input-group">
                <div className="input-group mb-3 has-validation">
                  <span className="input-group-text input-icon border-end-0 rounded-0 rounded-start">
                    <img src={iconAge} alt="age" />
                  </span>
                  <input
                    type="text"
                    placeholder="Enter your age"
                    name="age"
                    value={values.age}
                    className="form-control border-start-0 ms-0 ps-0 rounded-0 rounded-end"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.age && touched.age ? (
                    <div className="invalid-feedback d-block">{errors.age}</div>
                  ) : null}
                </div>
              </div>
              <FormGroup>
                <div className="d-flex justify-content-center ">
                  <FormCheck
                    type="radio"
                    label="Male"
                    id="radio1"
                    name="gender"
                    value="male"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.gender && touched.gender}
                    className="mx-3 radio-button-border"
                  />
                  <FormCheck
                    type="radio"
                    label="Female"
                    id="radio2"
                    name="gender"
                    value="female"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.gender && touched.gender}
                    className="mx-3 radio-button-border"
                  />
                  <FormCheck
                    type="radio"
                    label="Others"
                    id="radio3"
                    name="gender"
                    value="others"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.gender && touched.gender}
                    className="mx-3 radio-button-border"
                  />
                </div>
                <FormControl.Feedback type="invalid">{errors.gender}</FormControl.Feedback>
              </FormGroup>
              <div className="d-grid gap-2">
                <button type="submit" className="primary-button fs-6 fw-semibold py-2 my-3">
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        )}
        {response && (
          <Modal.Body>
            <div className="d-flex flex-column align-items-center px-4 mx-3 justify-content-center modal_response-size">
              <img src={imageThankYou} alt="Thank you" className=" w-25" />
              <h6 className="z-1 mt-4">Thankyou for your response</h6>
            </div>
          </Modal.Body>
        )}
        <div className="position-relative z-0">
          <img
            src={imageFormBg}
            alt="Top Left"
            className="position-absolute top-0 start-100 translate-middle non_selectable"
          />
        </div>
      </div>
    </Modal>
  );
}

export default LearnerLeadNeedHelpModal;
