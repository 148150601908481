import { PropsWithChildren } from 'react';

import './index.css';

export type TextBarProp = {
  icon: string;
};
function TextBar({ children, icon }: PropsWithChildren<TextBarProp>) {
  return (
    <div className="text_bar_container d-flex bg-white rounded-pill align-items-center m-1 mt-4 pe-2 p-1">
      <div className="text-bar-icon d-flex justify-content-center align-items-center rounded-circle">
        <img src={icon} alt="text-bar-icon" className="img-fluid" />
      </div>
      <div className="text-wrap fs-6 fw-normal py-2 px-3">{children}</div>
    </div>
  );
}

export default TextBar;
