import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import { Buffer } from 'buffer';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import AboutUs from 'pages/AboutUs/about-us';
import BlogContent from 'pages/BlogContent';
import Blogs from 'pages/BlogListing/blogs';
import ContactUs from 'pages/ContactUs/contact-us';
import DrivingSchoolListing from 'pages/DrivingSchoolListing/driving-listing';
import DrivingSchoolProfile from 'pages/DrivingSchoolProfile/driving-school-profile';
// pages
import Home from 'pages/Home/Home';
import LearnDriving from 'pages/LearnDriving/learn-driving';
import PageNotFound from 'pages/PageNotFound';

import Footer from 'components/section/Footer/Footer';
import ListingFooter from 'components/section/ListingFooter/ListingFooter';
// components
import NavBar from 'components/section/NavBar/NavBar';

function App() {
  // #region App Initialization
  // setting axios defaults
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  axios.defaults.headers.common.Authorization = `Bearer ${process.env.REACT_APP_API_KEY}`;

  const location = useLocation();

  useEffect(() => {
    // initialize Hotjar
    // const siteId = parseInt(process.env.REACT_APP_HOTJAR_SITE_ID || '3487186', 10);
    // const hotjarVersion = parseInt(process.env.REACT_APP_HOTJAR_VERSION || '6', 10); // version of hotjar
    // hotjar.init(siteId, hotjarVersion);

    // initialize firebase
    const app = initializeApp(
      JSON.parse(
        Buffer.from(process.env.REACT_APP_FIREBASE_CONFIG ?? '', 'base64').toString('ascii')
      )
    );
    getAnalytics(app);
  }, []);
  // #endregion

  return (
    <HelmetProvider>
      <>
        <div className="alert alert-primary text-center " role="alert">
          Love this Website? It is a product of{' '}
          <a href="https://xccessor.com">Xccessor Innovations LLP</a>
        </div>
        <NavBar />
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Routes>
          <Route index element={<LearnDriving />} />
          <Route path="partner" element={<Home />} />
          <Route path="listing" element={<DrivingSchoolListing />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="blogs/:id" element={<BlogContent />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="p/:id" element={<DrivingSchoolProfile />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {location.pathname.startsWith('/listing') || location.pathname === '/' ? (
          <ListingFooter />
        ) : (
          <Footer />
        )}
      </>
    </HelmetProvider>
  );
}

export default App;
