import * as Yup from 'yup';

const modal = Yup.object({
  name: Yup.string()
    .min(2, 'Name must be more than 2 character')
    .max(100, 'Name must be less than 100 character')
    .required('Please enter your name'),
  pincode: Yup.string()
    .min(6, 'Pincode must be 6 digits')
    .max(6, 'Pincode must be 6 digits')
    .required('Please your pincode to find you the nearest driving school'),
  gender: Yup.string().required('Please Select an Option'),
  age: Yup.number().min(12, 'You seem to be underaged').max(120, 'Are you alive?'),
  mobile_number: Yup.string()
    .min(8, 'Phone number be more than 8 digits')
    .max(13, 'Phone number must be less than 13 digits')
    .required('Please provide us your contact details')
    .matches(/^(\+)?\d+$/, 'Please enter a valid phone number'),
});
export default modal;
