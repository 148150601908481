import { Container, Nav, Navbar } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { NavLink } from 'react-router-dom';

import imgLogo from 'assets/logo.svg';

import './NavBar.css';

function NavBar() {
  return (
    <Navbar collapseOnSelect expand="lg" className="drively-navbar">
      <Container>
        <Navbar.Brand href="/" className="mx-auto d-flex align-items-center">
          <Image
            alt="Drively"
            src={imgLogo}
            className="d-inline-block align-top drively-navbar__logo me-2"
          />
          <span className="d-inline-block align-centre drively-navbar__brand_name">Drively</span>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end align-items-center"
        >
          <Nav>
            <Nav.Link as={NavLink} to="/" eventKey="1" className="mt-1 fs-6 fw-medium">
              Learn Driving
            </Nav.Link>
            <Nav.Link as={NavLink} to="/partner" eventKey="2" className="mt-1 fs-6 fw-medium">
              List your Driving School
            </Nav.Link>
            <Nav.Link as={NavLink} to="/about" eventKey="3" className="mt-1 fs-6 fw-medium">
              About us
            </Nav.Link>
            <Nav.Link as={NavLink} to="/contact" eventKey="4" className="mt-1 fs-6 fw-medium">
              Contact us
            </Nav.Link>
            <Nav.Link as={NavLink} to="/blogs" eventKey="5" className="mt-1 fs-6 fw-medium">
              Blogs
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
