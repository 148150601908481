import { LearnerLeadResponseModel } from 'domain/LearnerLeadModel';

import LearnerLeadLocalDataSource from './LearnerLeadLocalSource';

class LearnerLeadLocalDataSourceImpl implements LearnerLeadLocalDataSource {
  key = 'learnerLead-';

  storeNewLearnerLead(lead: LearnerLeadResponseModel): void {
    if (lead.attributes?.driving_school)
      localStorage.setItem(`${this.key}${lead.attributes.driving_school}`, JSON.stringify(lead));
  }

  getLearnerLead(schoolId: number): LearnerLeadResponseModel | null {
    const newLead = localStorage.getItem(`${this.key}${schoolId}`);
    if (!newLead) return null;
    return JSON.parse(newLead);
  }

  // eslint-disable-next-line class-methods-use-this
  clearStore(): void {
    localStorage.clear();
  }
}

export default LearnerLeadLocalDataSourceImpl;
