import axios from 'axios';

import { ApiResponseMeta } from 'domain/ApiResponseMeta';
import { TestimonialModel } from 'domain/TestimonialModel';

import TestimonialDataSource from 'data/datasource/TestimonialDataSource';

/**
 * The API Response Model;
 */
type TestimonialResponse = {
  data: TestimonialModel[];
  meta: ApiResponseMeta;
};

/**
 * This is the implementation of the [TestimonialDataSource] to get the data from source: API(Server)
 */
export default class TestimonialApiDataSource implements TestimonialDataSource {
  path = `testimonials`;

  async getDrivers(): Promise<TestimonialModel[]> {
    const query = {
      filters: {
        type: 'dschool',
      },
      populate: {
        profile_image: {
          fields: ['alternativeText', 'url'],
        },
      },
    };
    const response = await axios.get<TestimonialResponse>(this.path, { params: query });
    return response.data.data;
  }

  async getLearners(): Promise<TestimonialModel[]> {
    const query = {
      filters: {
        type: 'learner',
      },
      populate: {
        profile_image: {
          fields: ['alternativeText', 'url'],
        },
      },
    };
    const response = await axios.get<TestimonialResponse>(this.path, { params: query });
    return response.data.data;
  }
}
