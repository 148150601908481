import { PropsWithChildren } from 'react';

import DrivingSchoolLeadForm from 'components/ui/DrivingSchoolLeadForm';
import SubHeading from 'components/ui/SubHeading/';

import imgFirst from 'assets/partner_with_us/first.svg';
import imgFirstMobile from 'assets/partner_with_us/first_mobile.svg';

import './CustomerSection.css';

type CustomerSectionProps = {
  onSuccess(): void;
};

function CustomerSection({ onSuccess }: PropsWithChildren<CustomerSectionProps>) {
  return (
    <div className="container">
      <div className="row d-flex justify-content-center flex-lg-row flex-column-reverse">
        {/* Heading and Form */}
        <div className="col-lg-6">
          <div className="row py-3 d-lg-block d-none">
            <SubHeading>
              Aapko और customers
              <br /> चाहिए क्या?😎
            </SubHeading>
          </div>

          <div className="row">
            <div className="col-lg-10">
              <DrivingSchoolLeadForm onSuccess={onSuccess} />
            </div>
            <div className="col-lg-2" /> {/* Empty div to control size of form in large screens */}
          </div>
        </div>

        {/* Image for screens greater than lg */}
        <div className="col-lg-6 d-lg-flex d-none justify-content-center">
          <img src={imgFirst} alt="driving-school" className="img-fluid" />
        </div>

        {/* Image for screens lower than lg */}
        <div className="col-lg-6 d-lg-none d-flex justify-content-center mb-3">
          <img src={imgFirstMobile} alt="driving-school" className="img-fluid" />
        </div>

        {/* Heading for screens lower than lg */}
        <div className="py-3 d-lg-none d-flex justify-content-center">
          <SubHeading>
            Aapko और customers
            <br /> चाहिए क्या?😎
          </SubHeading>
        </div>
      </div>
    </div>
  );
}

export default CustomerSection;
