import { PropsWithChildren } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import StarRating from 'components/ui/StarRating/StarRating';

import { TestimonialModel } from 'domain/TestimonialModel';

import imgBgCircle from 'assets/learn_driving/circle_bg.svg';

import './TestimonialSlider.css';

export type TestimonialProps = {
  testimonials: TestimonialModel[];
};

function TestimonialSlider({ testimonials }: PropsWithChildren<TestimonialProps>) {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 10_000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="container my-5">
      <div className="row">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Slider {...settings}>
          {testimonials.map((testimonial) => {
            return (
              <div
                className="d-flex flex-wrap justify-content-center"
                key={`testimonial-${testimonial.id}`}
              >
                <div className="card testimonial-card mt-5 m-3 position-relative">
                  <img
                    src={imgBgCircle}
                    alt="Top Left"
                    className="testimonial-card_bg-top non_selectable"
                  />
                  <div className="card-body">
                    <div className="d-flex justify-content-center align-items-center mt-5">
                      <StarRating rating={testimonial.attributes?.rating ?? 0} />
                    </div>
                    <p className="fs-6 fw-normal mt-4 text-center">
                      &quot;{testimonial.attributes?.testimonial}&quot;
                    </p>
                  </div>
                  <div className="card-footer bg-transparent border-0 d-flex justify-content-center flex-column px-3">
                    <h5 className="fs-6 fw-medium">{testimonial.attributes?.name}</h5>
                    <h5 className="fs-6 fw-medium fst-italic">{testimonial.attributes?.origin}</h5>
                  </div>

                  <img
                    src={imgBgCircle}
                    className="testimonial-card_bg-bottom non_selectable"
                    alt="Bottom Right"
                  />
                </div>
                <div className="testimonial-img rounded-circle">
                  <img
                    className="rounded-circle"
                    alt={
                      testimonial.attributes?.profile_image?.data?.attributes?.alternativeText ??
                      'testimonial-image'
                    }
                    src={testimonial.attributes?.profile_image?.data?.attributes?.url || undefined}
                  />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default TestimonialSlider;
