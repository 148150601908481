import { PropsWithChildren } from 'react';

import imgNoSchoolsFound from 'assets/driving_school/no_schools_found.svg';

type NoDataPlaceholderProp = {
  message: string;
};
function NoDataPlaceholder({ message }: PropsWithChildren<NoDataPlaceholderProp>) {
  return (
    <div className="container my-5">
      <div className="d-flex flex-column justify-content-center align-items-center page-not-found py-5">
        <img src={imgNoSchoolsFound} alt="No Data" className="img-fluid" />
        <p className="fw-normal fs-2 text-center">{message}</p>
      </div>
    </div>
  );
}

export default NoDataPlaceholder;
