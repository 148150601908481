import { useEffect, useState } from 'react';
import { Code } from 'react-content-loader';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Dependencies from 'Dependencies';
import Seo from 'Seo';

import NoDataPlaceholder from 'components/ui/NoDataPlaceholder/NoDataPlaceholder';

import { ApiError } from 'domain/ApiErrorResponse';
import { BlogsTopicModel } from 'domain/BlogsTopicModel';

import './blogs.css';

function Blogs() {
  const nav = useNavigate();

  const [blogsTopicWithBlogs, setBlogs] = useState<BlogsTopicModel[]>();
  useEffect(() => {
    async function fetch() {
      try {
        const blogs = await Dependencies.blogsRepo.getBlogTopicsWithBlogs();
        setBlogs(blogs);
      } catch (error) {
        const apiError = error as ApiError;
        if (apiError) {
          const errorMessage = apiError?.response?.data?.error?.message;
          toast.error(errorMessage);
        } else toast.error('Oops, Something went wrong!', { autoClose: 5000 });
        nav('/');
      }
    }
    fetch();
  }, [nav]);

  return (
    <>
      <Seo
        title="Drively: Learning to drive made easy"
        canonical="/blogs"
        description="Find the perfect driving school near you"
      />
      <div className="container-fluid blog-page__bg">
        <div className="container py-5">
          {/* When code is loading */}
          {blogsTopicWithBlogs === undefined && <Code />}

          {/* When there are no blogs */}
          {blogsTopicWithBlogs && blogsTopicWithBlogs.length === 0 && (
            <NoDataPlaceholder message="No blogs published yet, Coming Soon!" />
          )}

          {/* When we have blogs */}
          {blogsTopicWithBlogs?.map((blogTopic) => {
            return (
              <div className="pt-4" key={`topic-${blogTopic.id}`}>
                {/* Blog Topic */}
                <div className="d-flex justify-content-md-start justify-content-center">
                  <h4 className="blog-page__topic-row d-inline-flex px-5 py-2 rounded-pill fw-semibold">
                    {blogTopic.attributes?.name}
                  </h4>
                </div>

                {/* Blog Cards */}
                <div className="row x-scroller mt-4">
                  <div className="d-flex">
                    {blogTopic.attributes?.blogs?.data?.map((blog) => {
                      return (
                        <div key={`blog-${blogTopic.id}-${blog.id}`} className="me-4">
                          <div className="card blog-page__card rounded-5 m-2">
                            <img
                              src={blog.attributes?.thumbnail?.data?.attributes?.url || undefined}
                              alt={
                                blog.attributes?.thumbnail?.data?.attributes?.alternativeText ??
                                'blog-thumbnail'
                              }
                              className="card-img-top rounded-5 rounded-bottom"
                            />
                            <div className="card-body px-4">
                              <h4 className="card-title mt-1 fw-semibold fs-5">
                                {blog.attributes?.title}
                              </h4>
                              <p className="card-text fs-6 fw-normal">
                                {blog.attributes?.short_description}
                                <Link
                                  to={`${blog.attributes?.url}`}
                                  className="text-decoration-none ms-1"
                                >
                                  Read More
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Blogs;
