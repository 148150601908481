/* eslint-disable react/require-default-props */
import React, { SyntheticEvent } from 'react';

type SliderArrowProps = {
  onClick?: (event: SyntheticEvent) => void;
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  style?: React.CSSProperties;
  src: string;
};

function SliderArrow({ onClick, src, className }: SliderArrowProps) {
  return (
    <button type="button" className={className} onClick={onClick}>
      <img src={src} alt="next/previous" />
    </button>
  );
}
export default SliderArrow;
