import * as Yup from 'yup';

const contactUs = Yup.object({
  FullName: Yup.string()
    .min(2, 'Name must be more than 2 character')
    .max(100, 'Name must be less than 100 character')
    .required('Please enter your name'),
  MobileNumber: Yup.string()
    .min(8, 'Phone number be more than 8 digits')
    .max(13, 'Phone number must be less than 13 digits')
    .required('Please provide us your contact details')
    .matches(/^(\+)?\d+$/, 'Please enter a valid phone number'),
  Query: Yup.string()
    .min(2, 'Must be more than 2 character')
    .max(1000, 'Must be more less 1000 character')
    .required('Please enter your query'),
});
export default contactUs;
