import { useEffect, useState } from 'react';
import { List } from 'react-content-loader';
import { toast } from 'react-toastify';

import Dependencies from 'Dependencies';

import FaqAccordion from 'components/ui/FaqAccordion/FaqAccordion';
import SubHeading from 'components/ui/SubHeading';

import { ApiError } from 'domain/ApiErrorResponse';
import { FaqModel } from 'domain/FaqModel';

function FaqSection() {
  const [faqs, setFaqs] = useState<FaqModel[]>();
  useEffect(() => {
    async function fetch() {
      try {
        setFaqs(await Dependencies.faqRepo.getHome());
      } catch (error) {
        const apiError = error as ApiError;
        if (apiError) {
          const errorMessage = apiError?.response?.data?.error?.message;
          toast.error(errorMessage);
        } else toast.error('Oops, Something went wrong!', { autoClose: 5000 });
      }
    }
    fetch();
  }, []);

  return (
    <div className="container my-5">
      {!faqs && <List />}
      {faqs && faqs.length !== 0 && (
        <>
          <SubHeading underline={false}>सवाल आपके जवाब हमारे 😎</SubHeading>
          <FaqAccordion faqs={faqs} />
        </>
      )}
    </div>
  );
}

export default FaqSection;
