import SubHeading from 'components/ui/SubHeading/';
import TextBar from 'components/ui/TextBar/';

import imgGrowItem1 from 'assets/partner_with_us/grow_item1.svg';
import imgGrowItem2 from 'assets/partner_with_us/grow_item2.svg';
import imgGrowItem3 from 'assets/partner_with_us/grow_item3.svg';
import imgGrowItem4 from 'assets/partner_with_us/grow_item4.svg';
import imgGrowItem5 from 'assets/partner_with_us/grow_item5.svg';
import imgGrowItem6 from 'assets/partner_with_us/grow_item6.svg';
import imgGrowHeader from 'assets/partner_with_us/icon_grow_header.png';
import imgThird from 'assets/partner_with_us/third.svg';

import './GrowBizSection.css';

function GrowBizSection() {
  return (
    <div className="container py-5">
      <div className="row d-flex justify-content-center flex-lg-row flex-column-reverse">
        {/* Layout for lg screens */}
        <div className="col-lg-7 my-3 d-lg-block d-none">
          <div className="row py-3">
            <SubHeading>
              <div className="text-white">
                Grow your business <img src={imgGrowHeader} alt="grow icon money" />
              </div>
            </SubHeading>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center align-items-lg-start">
            <TextBar icon={imgGrowItem1}>
              <span className="fs-3 fw-medium">अपने local comeptition को पीछे छोड़े</span>
            </TextBar>
            <TextBar icon={imgGrowItem2}>
              <span className="fs-3 fw-medium">आप अपने area में famous हो जाओगे</span>
            </TextBar>
            <TextBar icon={imgGrowItem3}>
              <span className="fs-3 fw-medium">हाँ भाई, यह सब बिल्कुल free</span>
            </TextBar>
            <TextBar icon={imgGrowItem4}>
              <span className="fs-3 fw-medium">आसान और free marketing</span>
            </TextBar>
          </div>
        </div>

        {/* Layout for screens lower than lg */}
        <div className="col-lg-6 my-3 d-lg-none d-block mt-4">
          <div className="d-flex flex-column justify-content-center align-items-md-center align-items-start bg-white rounded-3 py-4 px-2">
            <div className="d-flex flex-row justify-content-center align-items-start m-1">
              <img src={imgGrowItem1} alt="item1" className="grow-biz_item-image" />
              <span className="ms-3 fs-6 fw-medium">अपने local comeptition को पीछे छोड़े</span>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-start m-1">
              <img src={imgGrowItem2} alt="item2" className="grow-biz_item-image" />
              <span className="ms-3 fs-6 fw-medium">आप अपने area में famous हो जाओगे</span>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-start m-1">
              <img src={imgGrowItem3} alt="item3" className="grow-biz_item-image" />
              <span className="ms-3 fs-6 fw-medium">हाँ भाई, यह सब बिल्कुल free</span>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-start m-1">
              <img src={imgGrowItem4} alt="item4" className="grow-biz_item-image" />
              <span className="ms-3 fs-6 fw-medium">आसान और free marketing</span>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center align-self-center mt-5">
              <div className="text-lg-start text-center mb-0">
                <h4 className="fw-semibold">Finally, जो की!</h4>
              </div>
              <div className="yellow-sm w-25 ms-2 mb-3 mt-0" />
            </div>

            <div className="d-flex flex-row justify-content-center align-items-start m-1">
              <img src={imgGrowItem5} alt="item5" className="grow-biz_item-image" />
              <span className="ms-3 fs-6 fw-medium">
                Aapko और customers देगा । हाँ जी, यह भी free 😱
              </span>
            </div>

            <div className="d-flex flex-row justify-content-center align-items-start m-1">
              <img src={imgGrowItem6} alt="item6" className="grow-biz_item-image" />
              <span className="ms-3 fs-6 fw-medium">finally आप अधिक कमाएंगे 🤑</span>
            </div>
          </div>
        </div>

        {/* Image */}
        <div className="col-lg-5 g-0 d-flex justify-content-center">
          <img
            srcSet={`${imgThird} 576w, ${imgThird} 768w, ${imgThird} 992w, ${imgThird} 1200w`}
            sizes="(max-width: 576px) 90px; (max-width: 768px) 400px, (max-width: 992px) 768px, 1200px"
            alt="Grow Your Business"
            className="img-fluid"
          />
        </div>

        {/* Heading for screens lower than lg */}
        <div className="row py-3 d-lg-none d-block">
          <SubHeading>
            <div className="text-white">
              Grow your business <img src={imgGrowHeader} alt="grow icon money" />
            </div>
          </SubHeading>
        </div>
      </div>
    </div>
  );
}

export default GrowBizSection;
