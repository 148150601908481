import { TestimonialModel } from 'domain/TestimonialModel';

import TestimonialDataSource from 'data/datasource/TestimonialDataSource';
import { TestimonialRepository } from 'data/repository/TestimonialRepository';

/**
 * The actual Implementation of [TestimonialRepository]
 */
export default class TestimonialRepositoryImpl implements TestimonialRepository {
  dataSource: TestimonialDataSource;

  constructor(_dataSource: TestimonialDataSource) {
    this.dataSource = _dataSource;
  }

  getLearnersTestimonies(): Promise<TestimonialModel[]> {
    return this.dataSource.getLearners();
  }

  getDriversTestimonies(): Promise<TestimonialModel[]> {
    return this.dataSource.getDrivers();
  }
}
