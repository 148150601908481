import { PropsWithChildren } from 'react';
import { toast } from 'react-toastify';

import Dependencies from 'Dependencies';
import { useFormik } from 'formik';
import contactUs from 'schemas/validations/contact-us';

import { ApiError } from 'domain/ApiErrorResponse';
import { ContactUsLeadModel } from 'domain/ContactUsLeadModel';

import imgIconName from 'assets/contact_us/input_icon_name.svg';
import imgIconPhone from 'assets/contact_us/input_icon_phone.svg';
import imageFormBg from 'assets/partner_with_us/form-bg-element.svg';

import './index.css';

const initialValues = {
  FullName: '',
  MobileNumber: '',
  Query: '',
};
type ContactUsLeadFormProps = {
  onSuccess(): void;
};

function ContactUsLeadForm({ onSuccess }: PropsWithChildren<ContactUsLeadFormProps>) {
  const { values, handleSubmit, handleBlur, handleChange, touched, errors } = useFormik({
    initialValues,
    validationSchema: contactUs,
    onSubmit: (value, action) => {
      const payload: ContactUsLeadModel = {
        id: null,
        data: {
          full_name: value.FullName ?? '',
          phone_number: value.MobileNumber ?? '',
          query: value.Query || 'None',
        },
      };

      async function createNewLead() {
        try {
          await toast.promise(Dependencies.contactUsLeadRepo.createNewLead(payload), {
            pending: 'Sending Your Request!',
            success: {
              render() {
                return 'Thank you for your Interest, we will get back to you ASAP';
              },
            },
            error: {
              render({ data }) {
                const apiError = data as ApiError;
                if (apiError?.response?.data?.error?.message) {
                  const errorMessage = apiError?.response?.data?.error?.message;
                  return errorMessage;
                }
                return 'Oops, Something went wrong!';
              },
            },
          });
          action.resetForm();
          onSuccess();
        } catch (error) {
          const apiError = error as ApiError;
          if (apiError?.response?.data?.error?.message) {
            const errorMessage = apiError?.response?.data?.error?.message;
            toast.error(errorMessage);
          } else toast.error('Oops, Something went wrong!');
        }
      }
      createNewLead();
    },
  });
  return (
    <div className="col-lg-6 my-4">
      <div className="rounded-4 card contact-us_form-card p-4">
        <div className="position-relative">
          <img
            src={imageFormBg}
            alt="Top Left"
            className="position-absolute top-0 start-0 translate-middle"
          />
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <h5 className="d-flex d-md-none justify-content-center fw-semibold position-relative">
              Where can we reach you?
            </h5>

            <div className="input-group m-2 mt-4 has-validation">
              <span className="input-group-text input-icon border-end-0 rounded-0 rounded-start">
                <img src={imgIconName} alt="profile" />
              </span>
              <input
                className="form-control border-start-0 ms-0 ps-0 rounded-0 rounded-end"
                placeholder="&nbsp; Full name"
                type="text"
                name="FullName"
                value={values.FullName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.FullName && touched.FullName ? (
                <div className="invalid-feedback d-block">{errors.FullName}</div>
              ) : null}
            </div>
            <div className="input-group m-2 has-validation">
              <span className="input-group-text input-icon border-end-0 rounded-0 rounded-start">
                <img src={imgIconPhone} alt="phone" />
              </span>
              <input
                className="form-control border-start-0 ms-0 ps-0 rounded-0 rounded-end"
                placeholder="&nbsp; Enter mobile number"
                type="mobile"
                name="MobileNumber"
                value={values.MobileNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.MobileNumber && touched.MobileNumber ? (
                <div className="invalid-feedback d-block">{errors.MobileNumber}</div>
              ) : null}
            </div>
            <div className="input-group m-2 has-validation z-1">
              <textarea
                className="form-control"
                placeholder="Your query..."
                name="Query"
                value={values.Query}
                onChange={handleChange}
                onBlur={handleBlur}
                rows={5}
              />
              {errors.Query && touched.Query ? (
                <div className="invalid-feedback d-block">{errors.Query}</div>
              ) : null}
            </div>
            <div className="d-grid mt-2 text-center">
              <button type="submit" className="primary-button fs-5 fw-medium py-2">
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="position-relative z-0">
          <img
            src={imageFormBg}
            className="position-absolute top-0 start-100 translate-middle"
            alt="Bottom Right"
          />
        </div>
      </div>
    </div>
  );
}

export default ContactUsLeadForm;
