import React, { PropsWithChildren, useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Option } from 'react-google-places-autocomplete/build/types';
import { StylesConfig } from 'react-select';
import { Id, toast } from 'react-toastify';

import imgIconSearch from 'assets/learn_driving/icon_search.svg';
import imgIconLocation from 'assets/learn_driving/icon_target.svg';

import './LocationSearchForm.css';

export interface UserLocation {
  latitude?: number;
  longitude?: number;
}
type SearchFormProps = {
  locationValue: string;
  onSubmit(event: React.SyntheticEvent, locationData: Option | null): void;
  onLocationFetched(location: UserLocation): void;
};

function LocationSearchForm({
  locationValue,
  onSubmit,
  onLocationFetched,
}: PropsWithChildren<SearchFormProps>) {
  const [googleMapsLocation, setMapsLocation] = useState<Option | null>({
    label: locationValue,
    value: '',
  });
  const [fetchingLocation, setFetchingLocation] = useState<boolean>(false);
  const [toastId, setToastId] = useState<Id | undefined>();

  useEffect(() => {
    setMapsLocation({
      label: locationValue,
      value: '',
    });
  }, [locationValue]);

  useEffect(() => {
    let id;
    if (fetchingLocation) id = toast.info('Fetching Your Location..', { autoClose: false });
    else toast.dismiss(toastId);
    setToastId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingLocation]);

  const customStyles: StylesConfig<Option> = {
    control: (provided) => ({
      ...provided,
      borderColor: 'black',
      borderRadius: '0.625rem',
      height: '3.5rem',
      maxHeight: '3.5rem',
      boxShadow: '0rem 0.275rem 0rem black !important;',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'none',
    }),
  };

  function handleSubmit(event: React.SyntheticEvent) {
    if (googleMapsLocation && googleMapsLocation.value) onSubmit(event, googleMapsLocation);
    else {
      event.preventDefault();
      toast.error('Kindly Select a location');
    }
  }

  function handleLocateMeClick() {
    if (fetchingLocation) return;
    if (navigator.geolocation) {
      setFetchingLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          onLocationFetched({ latitude, longitude });
          setFetchingLocation(false);
        },
        (error) => {
          setFetchingLocation(false);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              toast.error('User denied the request for Geolocation.');
              break;
            case error.POSITION_UNAVAILABLE:
              toast.error('Location information is unavailable.');
              break;
            case error.TIMEOUT:
              toast.error('The request to get user location timed out.');
              break;
            default:
              toast.error('An unknown error occurred.');
              break;
          }
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser');
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row d-flex g-2 justify-content-center">
        <div className="col-9">
          <div className="row position-relative">
            <GooglePlacesAutocomplete
              onLoadFailed={() => toast.error('Google Maps API Failed! Contact Support.')}
              debounce={3}
              autocompletionRequest={{
                types: ['(cities)'],
                componentRestrictions: { country: ['IN'] },
              }}
              selectProps={{
                value: googleMapsLocation?.label ? googleMapsLocation : null,
                onChange: setMapsLocation,
                placeholder: 'Enter your city',
                styles: customStyles,
              }}
            />
            <button
              type="button"
              onClick={handleLocateMeClick}
              className="search-location-button p-lg-3 p-2 position-absolute rounded d-flex align-items-center"
            >
              <img className="search-location_icon" src={imgIconLocation} alt="location" />
              <span className="search-location_label d-lg-inline d-none ms-2 mt-1"> Locate Me</span>
            </button>
          </div>
        </div>
        <div className="col-3">
          <button type="submit" className="search-form-button rounded-3">
            <img src={imgIconSearch} alt="search" className="search-form-button_icon" />
            <span className="search-form-button_label d-lg-inline d-none"> &nbsp; Search </span>
          </button>
        </div>
      </div>
    </form>
  );
}

export default LocationSearchForm;
