import { useEffect, useState } from 'react';
import { Code } from 'react-content-loader';
import { useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { toast } from 'react-toastify';

import Dependencies from 'Dependencies';
import Seo from 'Seo';
import { round } from 'utils';

import LearnerLeadModal from 'components/section/Modals/LearnerLeadModal';
import NoDataPlaceholder from 'components/ui/NoDataPlaceholder/NoDataPlaceholder';

import { ApiError } from 'domain/ApiErrorResponse';
import { DrivingSchoolModel } from 'domain/DrivingSchoolModel';
import { LearnerLeadType } from 'domain/LearnerLeadModel';

import imgIconRtArrow from 'assets/driving_school/icon_rt_arrow.svg';
import imgIconShare from 'assets/driving_school/icon_share.png';
import imgReviewStar from 'assets/driving_school/review-star-filled.svg';
import imgSliderArrowNext from 'assets/driving_school/slider_arrow_next.svg';
import imgSliderArrowPrev from 'assets/driving_school/slider_arrow_prev.svg';
import imgVerifiedBanner from 'assets/profile/drively_verified_badge.svg';
import imgRatingStar from 'assets/profile/star.svg';

import './driving-school-profile.css';
import CourseCard from './ui/CourseCard/course-card';
import Rating from './ui/RatingCard/rating-card';
import ReviewCard from './ui/ReviewCard/review-card';
import SliderArrow from './ui/slider-arrow-button';

const courseBgColors = ['#FFF5E2', '#FAE7D5', '#E2EFFF'];

function DrivingSchoolProfile() {
  const { id } = useParams();
  const nav = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [ratingModal, setRatingModal] = useState(true);
  const [drivingSchool, setDrivingSchool] = useState<DrivingSchoolModel>();
  const [placeDetails, setPlaceDetail] = useState<google.maps.places.PlaceResult>();
  const [selectedCourseId, setSelectedCourseId] = useState<number | null>();

  function handleClose() {
    setRatingModal(false);
  }

  function handleModalToggle(courseId: number | null) {
    setModalVisible(!modalVisible);
    setSelectedCourseId(courseId);
  }
  const handleModalClose = () => {
    setModalVisible(false);
  };

  const shareURL = () => {
    // You might want to add the domain to the path as well depending on your requirement
    const urlToShare = `${window.location.origin}/p/${id}`;
    if (navigator.share) {
      navigator
        .share({
          title: 'Share this Driving school',
          text: 'Checkout this Driving School!',
          url: urlToShare,
        })
        .catch(() => toast.error('Something went wrong sharing the url'));
    } else {
      // Fallback: copy URL to clipboard
      navigator.clipboard
        .writeText(`Checkout this Driving School: ${urlToShare}`)
        .then(() => {
          toast.info('URL copied to your clipboard!');
        })
        .catch(() => {
          toast.error('Could not copy text: ');
        });
    }
  };

  useEffect(() => {
    async function getReviews(placesId: string) {
      const request = {
        placeId: placesId,
        fields: ['reviews', 'rating', 'user_ratings_total'],
      };

      if (!window.google) return;

      try {
        const service = new google.maps.places.PlacesService(document.createElement('div'));
        service.getDetails(request, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && place) {
            setPlaceDetail(place);
          }
        });
      } catch (error) {
        toast.error('Oops, Something went wrong!');
      }
    }

    async function fetch() {
      let school;
      try {
        school = await Dependencies.drivingSchoolRepo.getDrivingSchool(id || 'sample');
      } catch (error) {
        const apiError = error as ApiError;
        if (apiError) {
          const errorMessage = apiError?.response?.data?.error?.message;
          toast.error(errorMessage);
        } else toast.error('Oops, Something went wrong!', { autoClose: 5000 });
      }
      if (!school) nav('/school_not_found');
      else {
        setDrivingSchool(school);
        if (school.attributes?.google_places_data?.placesId)
          getReviews(school.attributes?.google_places_data.placesId);
      }
    }
    fetch();
  }, [id, nav]);

  function getCarMakes(): string {
    const makes = drivingSchool?.attributes?.driving_school_courses?.data?.flatMap(
      (course) => course.attributes?.vehicle_make
    );
    if (!makes || makes?.length === 0) return 'None';
    return makes?.join(', ') || 'loading...';
  }

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 5_000,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SliderArrow src={imgSliderArrowNext} />,
    prevArrow: <SliderArrow src={imgSliderArrowPrev} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  if (!drivingSchool) return <Code />;

  return (
    <>
      <Seo
        title="Drively: Learning to drive made easy"
        canonical={`/p/${id}`}
        description="Find the perfect driving school near you"
      />
      {/* Driving School Header */}
      <section id="top">
        <div className="container mt-5">
          <div className="row position-relative justify-content-center px-3">
            {/* Heading */}
            <h3 className="d-flex flex-wrap justify-content-center position-absolute driving-school-profile__about-name pt-lg-4 py-2 pb-lg-2 px-lg-5 fs-4 fw-bold text-center">
              {drivingSchool.attributes?.name}
            </h3>

            {/* School Header Card */}
            <div className="d-flex flex-column flex-lg-row driving-school-profile__about rounded-4 px-4 py-2">
              {/* Short Description */}
              <div className="col-lg-5 col-12 order-lg-0 order-1">
                <div className="row align-items-center h-100">
                  <p className="fw-medium fs-6 lh-3 text-center text-lg-start ps-lg-4 pe-lg-5 mt-3 mt-lg-0">
                    {drivingSchool.attributes?.short_description}
                  </p>
                </div>
              </div>

              {/* Additional Details */}
              <div className="col-lg-4 col-12 order-lg-1 order-2 d-flex align-items-center">
                <div className="row mt-2">
                  {/* Opening Hours */}
                  <div className="d-flex flex-wrap justify-content-lg-start justify-content-center">
                    <span className="fw-semibold fs-6 text-primary pe-2">Open hours:-</span>
                    <span className="fw-semibold fs-6">
                      {drivingSchool.attributes?.operational_hours}
                    </span>
                  </div>

                  {/* Google Rating */}
                  <div className="d-flex flex-wrap justify-content-lg-start justify-content-center mt-2">
                    <span className="fw-normal fs-6 pe-2">Google rating:- </span>
                    <span className="fw-semibold fs-6 pe-1">{placeDetails?.rating || 'NA'}</span>
                    <span>
                      <img
                        src={imgReviewStar}
                        alt="drively-rating"
                        className="img-responsive ms-1 pe-2 w-75"
                        style={{ height: '0.75rem' }}
                      />
                    </span>
                    <span className="fw-normal fs-6">
                      {' '}
                      ({placeDetails?.user_ratings_total ?? -1} reviews)
                    </span>
                  </div>

                  {/* Overall Experience */}
                  <div className="d-flex flex-wrap justify-content-lg-start justify-content-center mt-2">
                    <span className="fw-normal fs-6 me-2">Experience:- </span>
                    <span className="fw-semibold fs-6">
                      {new Date().getFullYear() -
                        (drivingSchool.attributes?.incorporation_year ?? 2022)}{' '}
                      years
                    </span>
                  </div>

                  {/* Buttons */}
                  <div className="d-inline-flex justify-content-lg-start justify-content-center mt-4 mb-4 mb-lg-0">
                    <div className="col-8">
                      <button
                        type="button"
                        onClick={() => handleModalToggle(null)}
                        className="d-flex driving-school-profile__about-btn-contact-us rounded-pill border-0 py-2 px-2 w-100 justify-content-center fs-5"
                      >
                        Contact now{' '}
                        <span>
                          <img
                            src={imgIconRtArrow}
                            alt="contact arrow"
                            className="driving-school-profile__about-btn-contact-us-arrow"
                          />
                        </span>
                      </button>
                    </div>

                    <div className="col-1">
                      <button type="button" onClick={shareURL} className="bg-transparent border-0">
                        <img src={imgIconShare} alt="share" className="img-responsive" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Profile Image */}
              <div className="col-lg-3 col-12 d-inline order-lg-2 order-0 mt-lg-1 mt-5">
                <div className="d-flex justify-content-lg-end justify-content-center mt-lg-0 mt-3">
                  <div className="d-flex flex-column position-relative rounded-4 bg-white driving-school-profile__about-profile">
                    {/* Hero Image */}
                    <img
                      src={
                        drivingSchool.attributes?.primary_contact_image?.data?.attributes?.url ||
                        undefined
                      }
                      alt={
                        drivingSchool.attributes?.primary_contact_image?.data?.attributes
                          ?.alternativeText || 'contact-image'
                      }
                      className="img-responsive driving-school-profile__hero_image rounded-top"
                    />

                    {/* Google Rating */}
                    <div className="position-absolute driving-school-profile__about-profile-rating">
                      <img
                        src={imgRatingStar}
                        alt="star"
                        className="mb-1 px-1 mt-1"
                        style={{ height: '0.65rem' }}
                      />
                      <span style={{ fontSize: '0.65rem' }}>
                        {round(drivingSchool.attributes?.drively_rating ?? -1, 1)}
                      </span>
                    </div>

                    {/* Primary Contact Name */}
                    <div className="driving-school-profile__about-profile-name bg-white rounded-top rounded-4">
                      {drivingSchool.attributes?.verified && (
                        <img src={imgVerifiedBanner} alt="verified-badge" />
                      )}
                      <h4 className="fs-6 fw-semibold text-center p-1">
                        {drivingSchool.attributes?.primary_contact_name}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Image Slider */}
      {drivingSchool.attributes?.slider_images?.data &&
        drivingSchool.attributes?.slider_images.data.length !== 0 && (
          <div className="container py-4 my-5">
            <div className="row">
              <div className="col-12">
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Slider {...settings}>
                  {drivingSchool.attributes?.slider_images.data.map((sliderImage) => {
                    return (
                      <img
                        key={`img-slider-${sliderImage.id}`}
                        src={sliderImage.attributes?.url || undefined}
                        className="img-responsive driving-school-profile__slider_img rounded-4 p-1"
                        alt={sliderImage.attributes?.alternativeText || 'slider-image'}
                      />
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        )}

      {/* Courses Offered */}
      <section id="courses">
        <div className="container">
          <div className="row">
            <h1 className="fw-bold fs-2 text-center">Coursework Information</h1>
            <h2 className="fw-normal fs-4 text-center">Car available - {getCarMakes()}</h2>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              {drivingSchool.attributes?.driving_school_courses?.data &&
                drivingSchool.attributes?.driving_school_courses.data.length === 0 && (
                  <NoDataPlaceholder message="No Courses Are offered by this Driving school for now, Please come back again later." />
                )}
              {drivingSchool.attributes?.driving_school_courses?.data?.map((course, index) => (
                <CourseCard
                  key={`course-${course.id}`}
                  courseInfo={course}
                  bgColor={courseBgColors[index % courseBgColors.length]}
                  onAvailClicked={() => {
                    handleModalToggle(course.id ?? null);
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Google Review */}
      <section id="review">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              {placeDetails?.reviews?.map((review) => (
                <ReviewCard key={`review-${review.time}`} reviewInfo={review} />
              ))}
            </div>
          </div>

          {/* Page CTA */}
          <div className="d-flex justify-content-center align-items-center my-5">
            {/* <Link to={placeDetails?.url || '#'}>
              <button type="button" className="btn btn-primary py-2 px-3 me-5">
                View More
              </button>
            </Link> */}
            <a className="fs-5 text-reset text-light" href="#top">
              Move to top
            </a>
          </div>
        </div>
      </section>
      <div className="sticky-bottom d-sm-none">
        {ratingModal && <Rating onClose={() => handleClose()} dSchoolId={drivingSchool.id ?? -1} />}
      </div>
      <div className=" position-fixed top-50 end-0 d-none d-sm-flex">
        {ratingModal && <Rating onClose={() => handleClose()} dSchoolId={drivingSchool.id ?? -1} />}
      </div>

      {/* Modal 1 Starts */}
      {modalVisible && (
        <LearnerLeadModal
          onClose={handleModalClose}
          drivingSchool={drivingSchool}
          selectedCourse={selectedCourseId || null}
          type={
            selectedCourseId
              ? LearnerLeadType.course_selected
              : LearnerLeadType.driving_school_selected
          }
        />
      )}
    </>
  );
}

export default DrivingSchoolProfile;
