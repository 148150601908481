import { PropsWithChildren } from 'react';

import empty from 'assets/testimonials/review-star-empty.svg';
import filled from 'assets/testimonials/review-star-filled.svg';
import half from 'assets/testimonials/review-star-half.svg';

import './StarRating.css';

export type StarRatingProps = {
  rating: number;
};

function StarRating({ rating }: PropsWithChildren<StarRatingProps>) {
  const roundedRating = Math.round(rating * 2) / 2; // Round to nearest 0.5

  const starIcons = Array.from({ length: 5 }, (_, i) => {
    const diff = roundedRating - i;
    if (diff >= 1) return <img className="star" src={filled} alt={`full-${i}`} key={i} />;
    if (diff >= 0.5) return <img className="star" src={half} alt={`half-${i}`} key={i} />;
    return <img className="star" src={empty} alt={`empty-${i}`} key={i} />;
  });

  return <div className="star-rating">{starIcons}</div>;
}

export default StarRating;
