import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DrivingCourseModel } from 'domain/DrivingCourseModel';

import imgCourseDays from 'assets/driving_school/course_icon_days.svg';
import imgCourseDuration from 'assets/driving_school/course_icon_duration.svg';
import imgCourseVehicleCar from 'assets/driving_school/course_icon_vehicle_car.svg';
import imgIconRtArrow from 'assets/driving_school/icon_rt_arrow.svg';

import './course-card.css';

type CourseCardProp = {
  courseInfo: DrivingCourseModel;
  bgColor: string;
  onAvailClicked: (courseId: number) => void;
};

function CourseCard({ courseInfo, bgColor, onAvailClicked }: CourseCardProp) {
  return (
    <div className="row g-0 justify-content-center position-relative">
      {/* Course Name */}
      <h4 className="position-absolute driving-course-item__name py-2 d-inline text-center fs-3 px-5 pt-3">
        {courseInfo.attributes?.package_name}
      </h4>

      {/* Course Card Content */}
      <div
        className="row justify-content-start border-0 rounded-4 mb-5 py-3 px-3 align-items-center"
        style={{ backgroundColor: bgColor }}
      >
        {/* Vehicle Image */}
        <div className="col-lg-6 col-12 mt-lg-2 mt-5">
          <img
            src={courseInfo.attributes?.vehicle_image?.data?.attributes?.url || undefined}
            alt={
              courseInfo.attributes?.vehicle_image?.data?.attributes?.alternativeText ||
              'vehicle-image'
            }
            className="img-responsive driving-course-item__vehicle-image rounded-4"
          />
        </div>

        {/* Course Details and Info */}
        <div className="col-lg-6 col-12 mt-lg-5 mt-2 pe-lg-5 p-0 justify-content-center">
          {/* Course Info */}
          <div className="d-flex flex-row flex-wrap justify-content-around">
            {/* Vehicle Type */}
            <div className="col-4 driving-course-item__info-badge p-2 mt-2">
              <div className="d-flex justify-content-center align-items-center flex-wrap g-0 px-md-3">
                <img src={imgCourseVehicleCar} alt="course-vehicle-type" />{' '}
                <span className="ms-2 fw-lighter"> {courseInfo.attributes?.vehicle_type}</span>
              </div>
            </div>

            {/* Course Duration in Days */}
            <div className="col-4 driving-course-item__info-badge p-2 mt-2">
              <div className="d-flex justify-content-center align-items-center flex-wrap px-md-3">
                <img src={imgCourseDays} alt="course-days" />{' '}
                <span className="ms-2 fw-lighter">{courseInfo.attributes?.duration_days} days</span>
              </div>
            </div>

            {/* Course Duration per Day */}
            <div className="col-4 driving-course-item__info-badge p-2 mt-2">
              <div className="d-flex justify-content-center align-items-center flex-wrap">
                <img src={imgCourseDuration} alt="course-duration" />{' '}
                <span className="ms-2 fw-lighter">{courseInfo.attributes?.duration}</span>
              </div>
            </div>
          </div>

          {/* CTA Buttons */}
          <div className="d-flex flex-row justify-content-around align-items-center mt-4">
            {/* Course Cost */}
            <div className="col-6 col-sm-4">
              <div className="row">
                <span className="fs-6 fw-normal text-start">Starting at</span>
                <h3 className="fs-4 fw-bold driving-course-item__price text-center">
                  <FontAwesomeIcon icon={faIndianRupeeSign} size="xs" className="pe-1" />
                  {courseInfo.attributes?.cost}/- <sub className="fs-6">only</sub>
                </h3>
              </div>
            </div>

            {/* Course CTA */}
            <div className="col-6 col-sm-4">
              <button
                type="button"
                className="d-flex w-100 justify-content-center driving-course-item__cta rounded-pill border-0 px-2 fs-6 py-2"
                onClick={() => {
                  if (courseInfo.id) onAvailClicked(courseInfo.id);
                }}
              >
                Avail now{' '}
                <span>
                  <img src={imgIconRtArrow} alt="course-arrow" className="img-fluid" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
