import { Helmet } from 'react-helmet-async';

interface SeoProps {
  title: string;
  canonical: string;
  og?: OpenGraphMeta | undefined | null;
  robots?: string | undefined | null;
  author?: string | undefined | null;
  twitter?: TwitterMeta | undefined | null;
  keywords?: string | undefined | null;
  description?: string | undefined | null;
}
interface OpenGraphMeta {
  img: string | undefined | null;
  url: string | undefined | null;
  title: string | undefined | null;
  description: string | undefined | null;
}

interface TwitterMeta {
  img: string | undefined | null;
  title: string | undefined | null;
  description: string | undefined | null;
  publisher_handle: string | undefined | null;
}

function Seo({ title, canonical, description, og, robots, twitter, keywords, author }: SeoProps) {
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={canonical} />

      {description && <meta name="description" content={description} />}
      {author && <meta name="author" content={author} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {robots && <meta name="robots" content={robots} />}

      {og?.title && <meta property="og:title" content={og?.title} />}
      {og?.description && <meta property="og:description" content={og?.description} />}
      {og?.url && <meta property="og:url" content={og?.url} />}
      {og?.img && <meta property="og:image" content={og?.img} />}

      {twitter?.img && <meta name="twitter:card" content={twitter?.img} />}
      {twitter?.publisher_handle && (
        <meta name="twitter:site" content={twitter?.publisher_handle} />
      )}
      {twitter?.title && <meta name="twitter:title" content={twitter?.title} />}
      {twitter?.description && <meta name="twitter:description" content={twitter?.description} />}
      {twitter?.img && <meta name="twitter:image" content={twitter?.img} />}
    </Helmet>
  );
}
Seo.defaultProps = {
  og: undefined,
  robots: undefined,
  author: undefined,
  twitter: undefined,
  keywords: undefined,
  description: undefined,
};
export default Seo;
