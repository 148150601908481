import { PropsWithChildren } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import { FaqModel } from 'domain/FaqModel';

import './FaqAccordion.css';

export type QnASectionProps = {
  faqs: FaqModel[];
};

function FaqAccordion({ faqs }: PropsWithChildren<QnASectionProps>) {
  return (
    <div className="row mt-3">
      <Accordion flush id="faqAccordion">
        {faqs.map((faq) => {
          return (
            <Accordion.Item
              eventKey={`faq-${faq.id}`}
              bsPrefix="accordion-item border-bottom"
              key={`faq-${faq.id}`}
              className="my-4"
            >
              <Accordion.Header bsPrefix="accordion-header qna-section_accordion-header">
                <span className="fs-5 fw-medium faq_mobile_font-size">
                  {faq.attributes?.question}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <span className="fs-5 fw-medium faq_mobile_font-size">
                  {faq.attributes?.answer}
                </span>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
}

export default FaqAccordion;
