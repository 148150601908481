import { useEffect, useState } from 'react';
import { List } from 'react-content-loader';
import { toast } from 'react-toastify';

import Dependencies from 'Dependencies';

import SubHeading from 'components/ui/SubHeading';
import TestimonialSlider from 'components/ui/TestimonialSlider/TestimonialSlider';

import { ApiError } from 'domain/ApiErrorResponse';
import { TestimonialModel } from 'domain/TestimonialModel';

function TestimonialsSection() {
  const [testimonies, setTestimonies] = useState<TestimonialModel[]>();
  useEffect(() => {
    async function fetch() {
      try {
        setTestimonies(await Dependencies.testimonyRepo.getDriversTestimonies());
      } catch (error) {
        const apiError = error as ApiError;
        if (apiError) {
          const errorMessage = apiError?.response?.data?.error?.message;
          toast.error(errorMessage);
        } else toast.error('Oops, Something went wrong!', { autoClose: 5000 });
      }
    }
    fetch();
  }, []);
  return (
    <div className="container">
      {!testimonies && <List />}
      {testimonies && testimonies.length > 0 && (
        <>
          <SubHeading underline={false}>हमारी कहानी आपकी जुबानी 🤗</SubHeading>
          <TestimonialSlider testimonials={testimonies} />
        </>
      )}
    </div>
  );
}

export default TestimonialsSection;
