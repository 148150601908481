import { PropsWithChildren, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { faArrowRightLong, faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { round } from 'utils';

import { DrivingSchoolCourses, DrivingSchoolModel } from 'domain/DrivingSchoolModel';

import imgIconServiceLicense from 'assets/learn_driving/icon_services_license.svg';
import imgIconServiceTrial from 'assets/learn_driving/icon_services_trial.svg';
import iconStar from 'assets/listing/icon_star.svg';
import imgVerifiedBadge from 'assets/listing/verified_badge.svg';

import './driving-list-item.css';

type DrivingListItemProp = {
  schoolModel: DrivingSchoolModel;
  onLocateMeClicked: () => void;
};

function DrivingListItem({
  schoolModel,
  onLocateMeClicked,
}: PropsWithChildren<DrivingListItemProp>) {
  const date = new Date();
  const [schoolRating, setSchoolRating] = useState<number>();
  function getStartingCostOfCourses(courses: DrivingSchoolCourses): number {
    if (!courses.data || courses.data.length === 0) return 0;
    return Math.min(...courses.data.flatMap((item) => item.attributes?.cost ?? 1_00_000));
  }

  useEffect(() => {
    if (!window.google) return;
    if (!schoolModel.attributes?.google_places_data?.placesId) {
      toast.error('Driving School is missing Google places id');
      return;
    }
    const request = {
      placeId: schoolModel.attributes.google_places_data.placesId,
      fields: ['rating', 'geometry'],
    };
    const placesService = new google.maps.places.PlacesService(document.createElement('div'));
    placesService.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && place?.rating && place.geometry) {
        setSchoolRating(place.rating);
      }
    });
  }, [schoolModel.id, schoolModel.attributes?.google_places_data?.placesId]);

  return (
    <div className="row position-relative justify-content-center px-3">
      <div className="row align-items-center driving-listing__card mb-5 rounded-4 border border-light bg-white g-0">
        {/* Hero Image */}
        <div className="col-lg-6 col-12 g-0 ps-lg-4">
          <div className="position-relative">
            {/* Verified badge */}
            {schoolModel.attributes?.verified && (
              <img
                src={imgVerifiedBadge}
                alt="verified"
                className="img-fluid position-absolute driving-listing__card-verified-badge"
              />
            )}
            {/* Hero Image */}
            <img
              src={schoolModel.attributes?.hero_image?.data?.attributes?.url || undefined}
              alt={
                schoolModel.attributes?.hero_image?.data?.attributes?.alternativeText ||
                'hero-image'
              }
              className="img-fluid driving-listing__card-hero-img rounded-4 border border-3 border-dark my-4"
            />
            {/* Drively Rating for Tab and Mobile */}
            <p className="d-flex d-lg-none position-absolute driving-listing__card-rating_mobile align-items-center py-1 ps-4 pe-1 text-end">
              <span>
                <b>Drively</b> Review {round(schoolModel.attributes?.drively_rating ?? -1, 1)}
              </span>
            </p>
            {/* Drively Rating for large screens */}
            <p className="d-lg-flex d-none position-absolute driving-listing__card-rating_mobile align-items-center py-1 ps-4 pe-1 text-end">
              <span
                className="ps-1 justify-content-center align-items-baseline"
                style={{ fontSize: '0.75rem' }}
              >
                {round(schoolModel.attributes?.drively_rating ?? -1, 1)}{' '}
                <img src={iconStar} alt="star" className="ps-1" style={{ width: '1rem' }} />
              </span>
            </p>
          </div>
        </div>

        {/* Driving School Info */}
        <div className="col-lg-5 col-12 p-3">
          {/* Title */}
          <div className="d-flex d-lg-inline justify-content-lg-start justify-content-center align-items-center">
            <span className="fs-5 fw-bold text-center">{schoolModel.attributes?.name}</span>
            <span className="d-none d-lg-inline fw-normal ms-3" style={{ fontSize: '1rem' }}>
              {date.getFullYear() -
                (schoolModel.attributes?.incorporation_year ?? date.getFullYear())}{' '}
              years in business
            </span>
          </div>

          {/* Google Maps Integration */}
          <div className="d-flex justify-content-lg-start justify-content-center align-items-center">
            <span className="fs-6 fw-medium lh-sm mx-2">
              {schoolModel.attributes?.distance ? (
                `${schoolModel.attributes?.distance} km away`
              ) : (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <span
                  className="fs-6 text-primary text-decoration-underline"
                  onClick={onLocateMeClicked}
                >
                  How far is this?
                </span>
              )}
            </span>
            <svg
              className="mx-2"
              width="8"
              height="8"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="6" cy="6" r="6" fill="#1375ED" />
            </svg>
            {schoolRating && (
              <>
                <span className="fs-6 fw-medium lh-sm ms-2">Google review</span>
                <span className="driving-listing__card-google-rating fs-6 fw-medium lh-sm ms-2">
                  {schoolRating}
                </span>
              </>
            )}
          </div>

          {/* Years of business in mobile screen */}
          <div className="d-flex d-lg-none justify-content-center mt-2">
            <span className="fw-normal" style={{ fontSize: '1rem' }}>
              {date.getFullYear() -
                (schoolModel.attributes?.incorporation_year ?? date.getFullYear())}{' '}
              years in business
            </span>
          </div>

          {/* Services */}
          {(schoolModel.attributes?.license_service || schoolModel.attributes?.trial_alloted) && (
            <div className="d-flex justify-content-lg-start justify-content-center align-items-center mt-4">
              {schoolModel.attributes?.license_service && (
                <button type="button" className="driving-listing__card-services-button px-2 py-1">
                  <img
                    src={imgIconServiceLicense}
                    alt="license-service"
                    className="img-responsive"
                    style={{ height: '1.25rem' }}
                  />
                  <span className="fw-normal" style={{ fontSize: '0.85rem' }}>
                    License service
                  </span>
                </button>
              )}

              {schoolModel.attributes?.trial_alloted && (
                <button
                  type="button"
                  className="driving-listing__card-services-button px-2 py-1 ms-3"
                >
                  <img
                    src={imgIconServiceTrial}
                    alt="trial-icon"
                    className="img-responsive"
                    style={{ height: '1.25rem' }}
                  />
                  <span className="fw-normal" style={{ fontSize: '0.85rem' }}>
                    Trial alloted
                  </span>
                </button>
              )}
            </div>
          )}
          {/* Price and CTA */}
          <div className="row align-items-center mt-4">
            <div className="col-6 text-lg-start text-center">
              <div className="row text-lg-start text-center">
                <span className="fs-6 fw-normal">Starting at</span>
              </div>
              <h3 className="driving-listing__card-cost fs-4 fw-bold mb-0">
                <FontAwesomeIcon icon={faIndianRupeeSign} style={{ height: '1.25rem' }} /> &nbsp;{' '}
                {schoolModel.attributes?.driving_school_courses
                  ? getStartingCostOfCourses(schoolModel.attributes.driving_school_courses)
                  : 0}{' '}
                /-{' '}
              </h3>
              <div className="fs-6 fw-bold text-end text-lg-start ms-5 mb-0 driving-listing__card-cost w-50">
                only
              </div>
            </div>

            {/* CTA Button */}
            <div className="col-6 px-3">
              <div className="row justify-content-lg-start justify-content-center">
                <Link to={`/p/${schoolModel.attributes?.school_name}`}>
                  <button
                    type="button"
                    className="driving-listing__card-cta-btn py-2 rounded-pill border-0"
                  >
                    <span className="fs-6 fw-normal profile_mobile_font-size">
                      View Profile <FontAwesomeIcon icon={faArrowRightLong} />
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Drively Rating in Large screens */}
      <div className="d-lg-flex d-none position-absolute driving-listing__card-rating align-items-top justify-content-center">
        <p className="text-center mt-3 mb-0 p-0 lh-1" style={{ fontSize: '0.625rem' }}>
          <b style={{ fontSize: '0.75rem' }}>Drively</b>
          <br />
          Review
          <br />
          <br />
          <span className="text-center fw-medium m-0 p-0" style={{ fontSize: '1.2rem' }}>
            {round(schoolModel.attributes?.drively_rating ?? -1, 1)}
          </span>
        </p>
      </div>
    </div>
  );
}

export default DrivingListItem;
