import axios from 'axios';

import { ApiResponseMeta } from 'domain/ApiResponseMeta';
import { LearnerLeadModel, LearnerLeadResponseModel } from 'domain/LearnerLeadModel';

import LearnerLeadDataSource from 'data/datasource/LearnerLeadDataSource';

type LearnerLeadResponse = {
  data: LearnerLeadResponseModel;
  meta: ApiResponseMeta;
};
class LearnerLeadApiDataSource implements LearnerLeadDataSource {
  path = 'learner-leads';

  async createNewLearnerLead(lead: LearnerLeadModel): Promise<LearnerLeadResponseModel> {
    const response = await axios.post<LearnerLeadResponse>(this.path, lead);
    // Hack! Since Response from API skips the Relations; add that manually!
    if (response.data.data.attributes?.driving_school)
      response.data.data.attributes.driving_school = lead.data.driving_school;
    if (response.data.data.attributes?.driving_school_course)
      response.data.data.attributes.driving_school_course = lead.data.driving_school_course;

    return response.data.data;
  }

  async setLearnerLeadReachable(
    lead: LearnerLeadResponseModel,
    reachable: boolean
  ): Promise<LearnerLeadResponseModel> {
    const putUrl = `${this.path}/${lead.id}`;
    const requestData = {
      data: {
        dschool_reachable: reachable,
      },
    };
    const response = await axios.put<LearnerLeadResponse>(putUrl, requestData);
    // Hack! Since Response from API skips the Relations; add that manually!
    if (response.data.data.attributes?.driving_school)
      response.data.data.attributes.driving_school = lead.attributes?.driving_school;
    if (response.data.data.attributes?.driving_school_course)
      response.data.data.attributes.driving_school_course = lead.attributes?.driving_school_course;

    return response.data.data;
  }
}

export default LearnerLeadApiDataSource;
