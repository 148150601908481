import { FaqModel } from 'domain/FaqModel';

import FaqDataSource from 'data/datasource/FaqDataSource';

/**
 * The actual Implementation of [FaqsRepository]
 */
export default class FaqRepository implements FaqRepository {
  dataSource: FaqDataSource;

  constructor(_dataSource: FaqDataSource) {
    this.dataSource = _dataSource;
  }

  getHome(): Promise<FaqModel[]> {
    return this.dataSource.getHome();
  }

  getPartnerWithUs(): Promise<FaqModel[]> {
    return this.dataSource.getPartnerWithUs();
  }
}
