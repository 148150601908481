import { PropsWithChildren } from 'react';

import './index.css';

interface SubheadingProp extends PropsWithChildren {
  underline?: boolean;
}

function SubHeading({ underline, children }: SubheadingProp) {
  return (
    <div className="row d-flex justify-content-md-start justify-content-center">
      <h2 className="subheading text-md-start text-center mb-0 fw-bold">{children}</h2>
      {underline && <div className="yellow-hr w-25 ms-2 mb-3 mt-0" />}
    </div>
  );
}
SubHeading.defaultProps = {
  underline: true,
};

export default SubHeading;
