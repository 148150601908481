import axios from 'axios';

import { ApiResponseMeta } from 'domain/ApiResponseMeta';
import { FaqModel } from 'domain/FaqModel';

import FaqDataSource from './FaqDataSource';

/**
 * The API Response Model;
 */

type FaqResponse = {
  data: FaqModel[];
  meta: ApiResponseMeta;
};
/**
 * This is the implementation of the [drivingcourseDataSource] to get the data from source: API(Server)
 */

export default class FaqApiDataSource implements FaqDataSource {
  path = `faqs`;

  async getHome(): Promise<FaqModel[]> {
    const query = {
      filter: {
        name: 'home',
      },
    };
    const response = await axios.get<FaqResponse>(this.path, { params: query });
    return response.data.data;
  }

  async getPartnerWithUs(): Promise<FaqModel[]> {
    const query = {
      filter: {
        name: 'partner-with-us',
      },
    };
    const response = await axios.get<FaqResponse>(this.path, { params: query });
    return response.data.data;
  }
}
