export interface LearnerLeadModel {
  data: Data;
}
export interface LearnerLeadResponseModel {
  id: number | undefined | null;
  attributes: Data | undefined | null;
}

export enum LearnerLeadType {
  needs_help = 'needs_help',
  driving_school_selected = 'driving_school_selected',
  course_selected = 'course_selected',
}
export interface Data {
  /**
   * Name of the Learner
   */
  name: string | undefined | null;
  /**
   * Mobile number of the Learner
   */
  mobile_number: string | undefined | null;
  /**
   * There are two forms from which Learner leads can be taken;
   * needs_help - This is when the learner is interested in getting a driving school but could not find one an needs help.
   * driving_school_selected - This is when the learner is placing a request after a driving school is selected.
   * course_selected - This is when the learner is placing a request after selecting a course.
   */
  type: string | undefined | null;
  /**
   * Gender of the user;
   * accepted values are:
   * - na
   * - male
   * - female
   * - other
   */
  gender: string | undefined | null;
  /**
   * The age of the user.
   */
  age: number | undefined | null;
  /**
   * This is used to confirm if the driving school was reachable or has responded after the contact details were shown.
   * Hint: this will be false by default; It will be updated using PUT once the user responds to on the "Were you able to contact the driving school?"
   */
  dschool_reachable: boolean | undefined | null;
  /**
   * The pincode of the user
   */
  pincode: number | undefined | null;
  /**
   * The name of the driving id! This will be empty when type = needs_help
   */
  driving_school: number | undefined | null;
  /**
   * The Driving School Course ID
   */
  driving_school_course: number | undefined | null;
}
