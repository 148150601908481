import { LearnerLeadModel, LearnerLeadResponseModel } from 'domain/LearnerLeadModel';

import LearnerLeadDataSource from 'data/datasource/LearnerLeadDataSource';
import LearnerLeadLocalDataSource from 'data/datasource/LearnerLeadLocalSource';
import LearnerLeadRepository from 'data/repository/LearnerLeadRepository';

class LearnerLeadRepositoryImpl implements LearnerLeadRepository {
  dataSource: LearnerLeadDataSource;

  localSource: LearnerLeadLocalDataSource;

  constructor(_dataSource: LearnerLeadDataSource, _localSource: LearnerLeadLocalDataSource) {
    this.dataSource = _dataSource;
    this.localSource = _localSource;
  }

  async createNewLearnerLead(data: LearnerLeadModel): Promise<LearnerLeadResponseModel> {
    const newLead = await this.dataSource.createNewLearnerLead(data);
    this.localSource.storeNewLearnerLead(newLead);
    return newLead;
  }

  async setLearnerLeadReachable(
    lead: LearnerLeadResponseModel,
    reachable: boolean
  ): Promise<LearnerLeadResponseModel> {
    const updatedLead = await this.dataSource.setLearnerLeadReachable(lead, reachable);
    this.localSource.storeNewLearnerLead(updatedLead);
    return updatedLead;
  }

  getLearnerLeadForSchool(schoolId: number): LearnerLeadResponseModel | null {
    return this.localSource.getLearnerLead(schoolId);
  }
}

export default LearnerLeadRepositoryImpl;
