import axios from 'axios';

import { ApiResponseMeta } from 'domain/ApiResponseMeta';
import { DrivingSchoolLeadModel } from 'domain/DrivingSchoolLeadModel';
import { DrivingSchoolModel } from 'domain/DrivingSchoolModel';

import DrivingSchoolDataSource from 'data/datasource/DrivingSchoolDataSource';

/**
 * The API Response Model;
 */
type DrivingSchoolsResponse = {
  data: DrivingSchoolModel[];
  meta: ApiResponseMeta;
};

/**
 * The API Response Model;
 */
type GetDrivingSchoolResponse = {
  data: DrivingSchoolModel[];
  meta: ApiResponseMeta;
};
/**
 * The API Response Model for Driving School Lead;
 */
type DrivingSchoolLeadsResponse = {
  data: unknown;
  meta: ApiResponseMeta;
};
/**
 * This is the implementation of the [DrivingSchoolDataSource] to get the data from source: API(Server)
 */

export default class DrivingSchoolApiDataSource implements DrivingSchoolDataSource {
  path = `driving-schools`;

  leadsPath = 'driving-school-leads';

  ratingsPath = 'driving-school-ratings';

  async getDrivingSchool(school_name: string): Promise<DrivingSchoolModel> {
    const query = {
      filters: {
        school_name,
      },
      populate: {
        google_places_data: {},
        hero_image: {
          fields: ['alternativeText', 'url'],
        },
        logo: {
          fields: ['alternativeText', 'url'],
        },
        slider_images: {
          fields: ['alternativeText', 'url'],
        },
        primary_contact_image: {
          fields: ['alternativeText', 'url'],
        },
        driving_school_courses: {
          populate: {
            vehicle_image: {
              fields: ['alternativeText', 'url'],
            },
          },
        },
      },
    };
    const response = await axios.get<GetDrivingSchoolResponse>(this.path, { params: query });

    return response.data.data[0];
  }

  async getDrivingSchoolListing(location: string): Promise<DrivingSchoolModel[]> {
    const query = {
      fields: [
        'name',
        'lat_long',
        'incorporation_year',
        'verified',
        'drively_rating',
        'google_places_data',
        'driving_school_courses',
        'hero_image',
        'school_name',
        'license_service',
        'trial_alloted',
      ],
      populate: {
        driving_school_courses: {
          fields: ['cost'],
        },
        hero_image: {
          fields: ['alternativeText', 'url'],
        },
      },
      filters: {
        location_search_string: {
          $containsi: location,
        },
      },
    };
    const response = await axios.get<DrivingSchoolsResponse>(this.path, { params: query });
    return response.data.data;
  }

  async createNewDrivingSchoolLead(data: DrivingSchoolLeadModel): Promise<boolean> {
    await axios.post<DrivingSchoolLeadsResponse>(this.leadsPath, data);
    return true;
  }

  async searchDrivingSchools(searchString: string): Promise<DrivingSchoolModel[]> {
    const query = {
      fields: ['name', 'school_name'],
      filters: {
        name: {
          $containsi: searchString,
        },
      },
    };
    const response = await axios.get<DrivingSchoolsResponse>(this.path, { params: query });
    return response.data.data;
  }

  async setDrivingSchoolRating(dSchoolId: number, rating: number): Promise<boolean> {
    const data = {
      data: {
        rating,
        driving_school: dSchoolId,
      },
    };
    await axios.post<DrivingSchoolLeadsResponse>(this.ratingsPath, data);
    return true;
  }
}
