import { DrivingSchoolLeadModel } from 'domain/DrivingSchoolLeadModel';
import { DrivingSchoolModel } from 'domain/DrivingSchoolModel';

import DrivingSchoolDataSource from 'data/datasource/DrivingSchoolDataSource';
import DrivingSchoolRepository from 'data/repository/DrivingSchoolRepository';

/**
 * The actual Implementation of [DrivingSchoolRepository]
 */

export default class DrivingSchoolRepositoryImpl implements DrivingSchoolRepository {
  dataSource: DrivingSchoolDataSource;

  constructor(_dataSource: DrivingSchoolDataSource) {
    this.dataSource = _dataSource;
  }

  getDrivingSchoolListing(location: string): Promise<DrivingSchoolModel[]> {
    return this.dataSource.getDrivingSchoolListing(location);
  }

  getDrivingSchool(school_name: string): Promise<DrivingSchoolModel> {
    return this.dataSource.getDrivingSchool(school_name);
  }

  searchDrivingSchools(searchString: string): Promise<DrivingSchoolModel[]> {
    return this.dataSource.searchDrivingSchools(searchString);
  }

  createNewDrivingSchoolLead(data: DrivingSchoolLeadModel): Promise<boolean> {
    return this.dataSource.createNewDrivingSchoolLead(data);
  }

  setDrivingSchoolRating(dSchoolId: number, rating: number): Promise<boolean> {
    return this.dataSource.setDrivingSchoolRating(dSchoolId, rating);
  }
}
