import * as Yup from 'yup';

const formFooter = Yup.object({
  drivingSchoolName: Yup.string().required('Please Select a valid School Name'),
  name: Yup.string()
    .min(2, 'Name must be more than 2 character')
    .max(100, 'Name must be less than 100 character')
    .required('Please enter your Name'),
  mobileNumber: Yup.string()
    .min(8, 'Phone number be more than 8 digits')
    .max(13, 'Phone number must be less than 13 digits')
    .required('Please enter your mobile number')
    .matches(/^(\+)?\d+$/, 'Please enter a valid mobile number'),
});
export default formFooter;
