import { useEffect, useState } from 'react';
import { Button, FormCheck, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dependencies from 'Dependencies';
import { useFormik } from 'formik';
import modal1 from 'schemas/validations/learner-lead';

import { ApiError } from 'domain/ApiErrorResponse';
import { DrivingSchoolModel } from 'domain/DrivingSchoolModel';
import {
  LearnerLeadModel,
  LearnerLeadResponseModel,
  LearnerLeadType,
} from 'domain/LearnerLeadModel';

import imgIconSocialFacebook from 'assets/driving_school/icon_school_facebook.svg';
import imgIconSocialInstagram from 'assets/driving_school/icon_school_instagram.svg';
import imgIconSocialWhatsapp from 'assets/driving_school/icon_school_whatsapp.svg';
import imageFormBg from 'assets/partner_with_us/form-bg-element.svg';
import iconAge from 'assets/partner_with_us/icon_input_age.svg';
import iconName from 'assets/partner_with_us/icon_input_name.svg';
import iconWhatsapp from 'assets/partner_with_us/icon_input_whatsapp.svg';
import iconNav from 'assets/partner_with_us/icon_nav.svg';

import './Modal.css';

const initialValues = {
  name: '',
  number: '',
  gender: 'na',
  age: undefined,
  dschool_reachable: false,
  driving_school: 0,
};
interface LearnerLeadModalProps {
  onClose: () => void;
  drivingSchool: DrivingSchoolModel;
  selectedCourse: number | null;
  type: LearnerLeadType;
}
function LearnerLeadModal({ onClose, drivingSchool, type, selectedCourse }: LearnerLeadModalProps) {
  const [showModal, setShowModal] = useState(true);
  const [learnerModal, setLearnerModal] = useState(true);
  const [contactModal, setContactModal] = useState(false);
  const [newLead, setNewLead] = useState<LearnerLeadResponseModel>();
  const [responseButton, setResponseButton] = useState(false);
  const [responseMessage, setResponseMessage] = useState(false);

  useEffect(() => {
    try {
      if (!drivingSchool.id) return;
      const localLead = Dependencies.learnerLeadRepo.getLearnerLeadForSchool(drivingSchool.id);
      if (localLead !== null) {
        setNewLead(localLead);
        setLearnerModal(false);
        setContactModal(true);
        if (!localLead.attributes?.dschool_reachable) setResponseButton(true);
      }
    } catch (error) {
      /* empty */
    }
  }, [drivingSchool.id]);

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema: modal1,
    onSubmit: () => {
      async function createNewLearnerLead() {
        const lead: LearnerLeadModel = {
          data: {
            name: values.name ?? '',
            mobile_number: values.number ?? '',
            type,
            gender: values.gender || 'na',
            age: values.age,
            dschool_reachable: null,
            pincode: undefined,
            driving_school: drivingSchool.id,
            driving_school_course: selectedCourse,
          },
        };
        try {
          setNewLead(await Dependencies.learnerLeadRepo.createNewLearnerLead(lead));
          setLearnerModal(false);
          setContactModal(true);
          setTimeout(() => setResponseButton(true), 5000);
        } catch (error) {
          const apiError = error as ApiError;
          if (apiError) {
            const errorMessage = apiError?.response?.data?.error?.message;
            if (
              apiError?.response?.status === 400 &&
              errorMessage === 'This attribute must be unique'
            ) {
              toast.error('This mobile number is already registered!', { autoClose: 5000 });
              setLearnerModal(false);
              setContactModal(true);
            } else {
              toast.error(errorMessage);
            }
          } else {
            toast.error('Oops, Something went wrong!');
          }
        }
      }

      createNewLearnerLead();
    },
  });
  const handleClose = () => {
    setShowModal(false);
    onClose(); // Close the modal
  };
  async function updateLearnerLead(reachability: boolean) {
    if (!newLead) return;

    try {
      await Dependencies.learnerLeadRepo.setLearnerLeadReachable(newLead, reachability);
      setResponseButton(false);
      setResponseMessage(true);
      setTimeout(() => setResponseMessage(false), 5000);
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError) {
        const errorMessage = apiError?.response?.data?.error?.message;
        toast.error(errorMessage);
      }
    }
  }
  return (
    <Modal show={showModal} onHide={handleClose} centered className="d-flex justify-content-center">
      <div className="overflow-hidden modal-background rounded-3 w-auto">
        <div className="position-relative z-0">
          <img
            src={imageFormBg}
            alt="Top Left"
            className="position-absolute top-0 start-0 translate-middle non_selectable"
          />
        </div>
        {learnerModal && (
          <Modal.Body className="z-1">
            <Button
              variant="secondary"
              onClick={handleClose}
              className="btn-close float-end"
              aria-label="Close"
            />
            <p className="d-flex justify-content-center fw-bold mt-4">Where can we reach you?</p>
            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <div className="input-group mb-3 has-validation">
                  <span className="input-group-text input-icon border-end-0 rounded-0 rounded-start">
                    <img src={iconName} alt="name" />
                  </span>
                  <input
                    className="form-control border-start-0 ms-0 ps-0 rounded-0 rounded-end"
                    placeholder="Enter your name"
                    name="name"
                    value={values.name}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.name && touched.name ? (
                    <div className="invalid-feedback d-block">{errors.name}</div>
                  ) : null}
                </div>
              </div>
              <div className="input-group">
                <div className="input-group mb-3 has-validation">
                  <span className="input-group-text input-icon border-end-0 rounded-0 rounded-start">
                    <img src={iconWhatsapp} alt="whatsapp" />
                  </span>
                  <input
                    type="text"
                    placeholder="Whatsapp number"
                    name="number"
                    value={values.number}
                    className="form-control border-start-0 ms-0 ps-0 rounded-0 rounded-end"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.number && touched.number ? (
                    <div className="invalid-feedback d-block">{errors.number}</div>
                  ) : null}
                </div>
              </div>
              <div className="input-group">
                <div className="input-group mb-3 has-validation">
                  <span className="input-group-text input-icon border-end-0 rounded-0 rounded-start">
                    <img src={iconAge} alt="age" />
                  </span>
                  <input
                    type="text"
                    placeholder="Enter your age"
                    name="age"
                    value={values.age}
                    className="form-control border-start-0 ms-0 ps-0 rounded-0 rounded-end"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.age && touched.age ? (
                    <div className="invalid-feedback d-block">{errors.age}</div>
                  ) : null}
                </div>
              </div>
              <FormGroup>
                <div className="d-flex justify-content-center ">
                  <FormCheck
                    type="radio"
                    label="Male"
                    id="radio1"
                    name="gender"
                    value="male"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.gender && touched.gender}
                    className="mx-3 radio-button-border"
                  />
                  <FormCheck
                    type="radio"
                    label="Female"
                    id="radio2"
                    name="gender"
                    value="female"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.gender && touched.gender}
                    className="mx-3 radio-button-border"
                  />
                  <FormCheck
                    type="radio"
                    label="Others"
                    id="radio3"
                    name="gender"
                    value="other"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.gender && touched.gender}
                    className="mx-3 radio-button-border"
                  />
                </div>
                <FormControl.Feedback type="invalid">{errors.gender}</FormControl.Feedback>
              </FormGroup>
              <div className="d-grid gap-2">
                <button
                  type="submit"
                  className="primary-button modal_button-background fs-6 fw-semibold py-2 my-3"
                >
                  Show contact details
                </button>
              </div>
            </form>
          </Modal.Body>
        )}
        {contactModal && (
          <Modal.Body className="z-1">
            <Button
              variant="secondary"
              onClick={handleClose}
              className="btn-close float-end"
              aria-label="Close"
            />
            <p className="d-flex justify-content-center fw-bold mt-4">Contact Details</p>
            <div className="d-flex justify-content-start">
              <div className="col-9 modal_contact-number rounded-2">
                <p className="mt-3 ms-3">{drivingSchool.attributes?.primary_contact}</p>
              </div>
              <a
                href={`tel:${drivingSchool.attributes?.primary_contact}`}
                className="col-3 align-items-center ps-2"
              >
                <Button style={{ height: '100%', width: '100%' }}>
                  <FontAwesomeIcon icon={faPhone} />
                </Button>
              </a>
            </div>

            <div className="modal_contact-address rounded-2 my-3">
              <p className="mt-3 ms-3">{drivingSchool.attributes?.address}</p>
              <a
                href={`https://www.google.com/maps/dir/?api=1&destination=${drivingSchool.attributes?.lat_long}`}
                className="d-flex justify-content-center align-items-center"
              >
                <img src={iconNav} alt="Nav Icon" />
              </a>
            </div>

            <div className="social-icon d-flex justify-content-center">
              {drivingSchool.attributes?.social_url_whatsapp && (
                <a href={drivingSchool.attributes?.social_url_whatsapp}>
                  <img src={imgIconSocialWhatsapp} alt="whatsapp" className="mx-3" />
                </a>
              )}

              {drivingSchool.attributes?.social_url_facebook && (
                <a href={drivingSchool.attributes?.social_url_facebook}>
                  <img src={imgIconSocialFacebook} alt="facebook" className="mx-3" />
                </a>
              )}
              {drivingSchool.attributes?.social_url_insta && (
                <a href={drivingSchool.attributes?.social_url_insta}>
                  <img src={imgIconSocialInstagram} alt="instagram" className="mx-3" />
                </a>
              )}
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-12 d-flex align-items-center">
                  {responseButton && (
                    <>
                      <span>Were you able to connect?</span>

                      <Button
                        type="submit"
                        className="ms-1"
                        onClick={() => updateLearnerLead(true)}
                      >
                        Yes
                      </Button>

                      <Button
                        type="button"
                        className="contact-modal_button-2 ms-2 ms-lg-4"
                        onClick={() => updateLearnerLead(false)}
                      >
                        No
                      </Button>
                    </>
                  )}
                </div>
                {responseMessage && (
                  <span className="d-flex justify-content-center">Thank you for your response</span>
                )}
              </div>
            </div>
          </Modal.Body>
        )}
        <div className="position-relative z-0">
          <img
            src={imageFormBg}
            alt="Top Right"
            className="position-absolute top-0 start-100 translate-middle non_selectable"
          />
        </div>
      </div>
    </Modal>
  );
}

export default LearnerLeadModal;
