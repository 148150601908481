import BlogApiDataSource from 'data/datasource/BlogApiDataSource';
import ContactUsLeadApiDataSource from 'data/datasource/ContactUsLeadApiDataScource';
import DrivingSchoolApiDataSource from 'data/datasource/DrivingSchoolApiDataSource';
import FaqApiDataSource from 'data/datasource/FaqApiDataSource';
import LearnerLeadApiDataSource from 'data/datasource/LearnerLeadApiDataScource';
import LearnerLeadLocalDataSourceImpl from 'data/datasource/LearnerLeadLocalDataSourceImpl';
import TestimonialApiDataSourceImpl from 'data/datasource/TestimonialApiDataSource';
import BlogRepository from 'data/repository/BlogRepository';
import BlogRepositoryImpl from 'data/repository/BlogRepositoryImpl';
import ContactUsLeadRepository from 'data/repository/ContactUsLeadRepository';
import ContactUsLeadRepositoryImpl from 'data/repository/ContactUsLeadRepositoryImpl';
import DrivingSchoolRepositoryImpl from 'data/repository/DrivingSchoolRepositoryImpl';
import FaqRepositoryImpl from 'data/repository/FaqRepositoryImpl';
import LearnerLeadRepository from 'data/repository/LearnerLeadRepository';
import LearnerLeadRepositoryImpl from 'data/repository/LearnerLeadRepositoryImpl';
import { TestimonialRepository } from 'data/repository/TestimonialRepository';
import TestimonialRepositoryImpl from 'data/repository/TestimonialRepositoryImpl';

/**
 * This class holds the app dependencies as a singleton
 */
export default class Dependencies {
  static testimonyRepo: TestimonialRepository = new TestimonialRepositoryImpl(
    new TestimonialApiDataSourceImpl()
  );

  static drivingSchoolRepo: DrivingSchoolRepositoryImpl = new DrivingSchoolRepositoryImpl(
    new DrivingSchoolApiDataSource()
  );

  static faqRepo: FaqRepositoryImpl = new FaqRepositoryImpl(new FaqApiDataSource());

  static blogsRepo: BlogRepository = new BlogRepositoryImpl(new BlogApiDataSource());

  static learnerLeadRepo: LearnerLeadRepository = new LearnerLeadRepositoryImpl(
    new LearnerLeadApiDataSource(),
    new LearnerLeadLocalDataSourceImpl()
  );

  static contactUsLeadRepo: ContactUsLeadRepository = new ContactUsLeadRepositoryImpl(
    new ContactUsLeadApiDataSource()
  );
}
