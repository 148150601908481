import { Link } from 'react-router-dom';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SubHeading from 'components/ui/SubHeading/';

import iconList from 'assets/partner_with_us/bullet_triangle.svg';
import listingMobile from 'assets/partner_with_us/listing_mobile.svg';
import imgSecond from 'assets/partner_with_us/second.svg';

import './ListingSection.css';

function ListingSection() {
  return (
    <div className="container mt-5">
      <div className="row d-flex justify-content-center align-items-center">
        {/* Image */}
        <div className="col-lg-5 d-none d-lg-flex">
          <img src={imgSecond} alt="listing" className="img-fluid" />
        </div>
        {/* Heading and Box content */}
        <div className="col-lg-7">
          <div className="row section-website-listing m-sm-2 justify-content-center p-sm-0 p-3">
            <SubHeading>आपको क्या मीलेगा? 🤔</SubHeading>
            <div className="card section-website-listing__card border border-2 border-dark border-top-0 p-0 d-md-flex d-none">
              <h3 className="card-title section-website-listing__card-title text-center py-4 px-2">
                Listing website with personalized link 🥳
              </h3>
              <div className="card-body p-1 p-xl-3">
                <ul className="list-group">
                  <li className="list-group-item text-left fs-3 fw-normal px-3">
                    <img className="me-3" src={iconList} alt="bullet" />
                    हाँ जी! आपकी listing website, free में
                  </li>
                  <li className="list-group-item text-left fs-3 fw-normal px-3">
                    <img className="me-3" src={iconList} alt="bullet" />
                    जो कि share करे बस 1 click में
                  </li>
                  <li className="list-group-item text-left fs-3 fw-normal px-3">
                    <img className="me-3" src={iconList} alt="bullet" />
                    आप इसे google listing में भी जोड़ सकते हैं
                  </li>
                  <li className="list-group-item text-left fs-3 fw-normal px-3">
                    <img className="me-3" src={iconList} alt="bullet" />
                    पर Google listing अब पुराना हो गया है
                  </li>
                </ul>
              </div>
              <div className="card-footer d-flex justify-content-center bg-transparent border-0 p-0 mb-4">
                <Link
                  to="/p/sample"
                  className="section-website-listing__card-footer fs-5 fw-medium py-3"
                >
                  Check out sample listing <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>

            {/* Show Image for mobile as it is not possible to customize the view for each screen size */}
            <img src={listingMobile} alt="Listing Mobile" className="d-fluid d-md-none g-0" />
            <div className="card-footer d-flex d-md-none justify-content-center bg-transparent border-0 p-0 mb-4">
              <Link
                to="/p/sample"
                className="section-website-listing__card-footer fs-5 fw-medium py-3"
              >
                Check out sample listing <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingSection;
