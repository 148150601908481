import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Seo from 'Seo';
import CustomerSection from 'pages/Home/sections/CustomerSection/CustomerSection';
import GrowBizSection from 'pages/Home/sections/GrowBizSection/GrowBizSection';
import ListingSection from 'pages/Home/sections/ListingSection/ListingSection';
import TestimonialsSection from 'pages/Home/sections/TestimonialsSection/TestimonialsSection';
import ToDoSection from 'pages/Home/sections/ToDoSection/ToDoSection';

import iconFormSuccess from 'assets/partner_with_us/form_success_image.svg';

import './Home.css';
import FaqSection from './sections/FaqSection/FaqSection';

function Home() {
  // #region model variables and methods
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // #endregion

  return (
    <>
      <Seo
        title="Drively: Powering Driving Schools"
        canonical="/partner"
        description="We provide technologies to empower the driving schools."
      />
      <section id="top" className="py-5">
        <CustomerSection onSuccess={handleShow} />
      </section>

      <section id="listing" className="py-5">
        <ListingSection />
      </section>

      <section id="growBiz" className="section-dark py-5 mt-5">
        <GrowBizSection />
      </section>

      <section id="todo" className="section-light-dark py-5 d-none d-lg-block">
        <ToDoSection />
      </section>

      <section id="faq" className="py-5">
        <FaqSection />
      </section>

      <section id="testimonials" className="py-5">
        <TestimonialsSection />
      </section>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className="home-modal_body">
          <button className="home-modal_close-button" onClick={handleClose} type="button">
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <div className="content mt-5">
            <img src={iconFormSuccess} alt="success" className="modal-image" />
            <p className="text-center text-white mt-3 fs-5">
              Thank you for your response, we will get back to you soon
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Home;
