import { Link } from 'react-router-dom';

import FooterForm from 'components/ui/FooterForm/FooterForm';

import imgIconInstagram from 'assets/footer/icon_social_instagram.svg';
import imgIconLinkedIn from 'assets/footer/icon_social_linkedin.svg';
import imgLogo from 'assets/footer/logo.svg';

import './ListingFooter.css';

function ListingFooter() {
  return (
    <footer className="container-fluid drively-footer pt-5">
      <div className="container text-center mt-5">
        <div className="row">
          <div className="col-lg-3 col-md-12 col-sm-12 pb-3">
            <div className="row drively-footer__branding d-flex justify-content-center justify-content-lg-start">
              <img src={imgLogo} alt="drively-logo" />
              <h4 className="fw-bolder">Drively</h4>
              <p className="fw-normal mt-3" style={{ fontSize: '0.625rem' }}>
                The website ends here, but your journey to learn driving from the best in town
                begins with Drively
              </p>
              <div className="row d-none d-lg-block mt-4">
                <h6 className="mb-3 fs-6 fw-semibold">Follow us</h6>
                <span>
                  <a
                    className="p-2"
                    href="https://www.instagram.com/xccessor/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="Instagram Logo"
                      src={imgIconInstagram}
                      className="social-icon-instagram"
                    />
                  </a>
                  <a
                    className="p-2"
                    href="https://www.linkedin.com/company/xccessor/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="LinkedIn Logo"
                      src={imgIconLinkedIn}
                      className="social-icon-linkedin"
                    />
                  </a>
                </span>
              </div>
            </div>
          </div>

          {/* <div className="col-1"> </div> */}

          <div className="col-lg-2 col-md-12 col-sm-12 pb-3">
            <div className="row drively-footer__useful-links gx-0">
              <h5>
                <u className="fs-6 fw-semibold">Useful Links</u>
              </h5>
              <Link to="/partner" className="mt-4">
                <span className="fw-medium" style={{ fontSize: '0.75rem' }}>
                  Partner With Us
                </span>
              </Link>
              <Link to="/" className="mt-1">
                <span className="fw-medium" style={{ fontSize: '0.75rem' }}>
                  Learn Driving
                </span>
              </Link>
              <Link to="/about" className="mt-1">
                <span className="fw-medium" style={{ fontSize: '0.75rem' }}>
                  About Us
                </span>
              </Link>
            </div>
          </div>

          <div className="col-lg-2 col-md-12 col-sm-12 pt-lg-4 pb-3">
            <div className="row drively-footer__useful-links">
              <Link to="/" className="mt-4">
                <span className="fw-medium" style={{ fontSize: '0.75rem' }}>
                  Privacy Policy
                </span>
              </Link>
              <Link to="/blogs" className="mt-1">
                <span className="fw-medium" style={{ fontSize: '0.75rem' }}>
                  Blogs
                </span>
              </Link>
              <Link to="/contact" className="mt-1">
                <span className="fw-medium" style={{ fontSize: '0.75rem' }}>
                  Contact Us
                </span>
              </Link>
            </div>
          </div>

          <div className="col-lg-5 pb-3 d-none d-lg-block">
            <FooterForm />
          </div>

          <div className="col-lg-3 col-md-12 col-sm-12 pb-3 d-lg-none mt-3">
            <div className="row">
              <h4 className="mb-3 fs-6 fw-semibold">Follow us</h4>
              <span>
                <a
                  className="p-2"
                  href="https://www.instagram.com/xccessor/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="Instagram Logo"
                    src={imgIconInstagram}
                    className="social-icon-instagram"
                  />
                </a>
                <a
                  className="p-2"
                  href="https://www.linkedin.com/company/xccessor/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="LinkedIn Logo" src={imgIconLinkedIn} className="social-icon-linkedin" />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <p className="fw-normal text-center text-lg-start" style={{ fontSize: '0.75rem' }}>
            Xccessor Innovations LLP © {new Date().getFullYear()}. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default ListingFooter;
