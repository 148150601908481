import { Link } from 'react-router-dom';

import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Seo from 'Seo';

import imgNoSchoolsFound from 'assets/driving_school/no_schools_found.svg';

import './index.css';

function PageNotFound() {
  return (
    <>
      <Seo
        title="Drively: Learning to drive made easy"
        canonical="/404"
        description="Find the perfect driving school near you"
      />
      <div className="container my-5">
        <div className="d-flex flex-column justify-content-center align-items-center page-not-found py-5">
          <img src={imgNoSchoolsFound} alt="Page Not Found" className="img-fluid" />
          <p className="fw-normal fs-2 text-center">404: Could not find the page you requested.</p>
          <Link to="/" className="d-flex justify-content-center">
            <button type="button">
              <FontAwesomeIcon icon={faHome} /> Take me home
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
