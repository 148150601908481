import StarRating from 'components/ui/StarRating/StarRating';

import imgGoogleReviewBadge from 'assets/driving_school/google_review_badge.svg';

import './review-card.css';

type ReviewCardProp = {
  reviewInfo: google.maps.places.PlaceReview;
};
function ReviewCard({ reviewInfo }: ReviewCardProp) {
  return (
    <div className="row g-0 google-review-card mt-4 rounded-4 position-relative p-3">
      <div className="row align-items-center">
        {/* Profile Image */}
        <div className="col-lg-3 col-12">
          <div className="d-flex flex-row flex-wrap p-md-3 pt-4 justify-content-start">
            <div className="d-flex pe-3">
              <img
                src={reviewInfo.profile_photo_url}
                alt="reviewer"
                className="img-responsive google-review-card__user-image"
              />
            </div>
            <div className="d-flex justify-content-center flex-column">
              <span className="fw-semibold" style={{ fontSize: '0.875rem' }}>
                {reviewInfo.author_name}
              </span>
              <div className="d-flex flex-wrap">
                <StarRating rating={reviewInfo.rating ?? 0} />
                <span className="fw-normal ms-2" style={{ fontSize: '0.75rem' }}>
                  {reviewInfo.relative_time_description}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Review Text */}
        <div className="col-lg-7 col-12 align-items-center py-lg-3 py-1">
          <p className="fw-small" style={{ fontSize: '0.875rem' }}>
            {reviewInfo.text}
          </p>
        </div>
      </div>

      {/* Badge */}
      <div className="d-flex position-absolute google-review-card_badge justify-content-end g-0">
        <img src={imgGoogleReviewBadge} alt="google-review-badge" className="img-fluid" />
      </div>
    </div>
  );
}

export default ReviewCard;
