import Seo from 'Seo';

import ContactUsLeadForm from 'components/ui/ContactUsLeadForm';
import SubHeading from 'components/ui/SubHeading';

import imgMail from 'assets/contact_us/email.svg';
import imgInstagram from 'assets/contact_us/instagram.svg';
import imgLinkedIn from 'assets/contact_us/linkedin.svg';
import imgPhone from 'assets/contact_us/phone.svg';
import imgWhatsapp from 'assets/contact_us/whatsapp.svg';

import './contact-us.css';

function ContactUs() {
  return (
    <>
      <Seo
        title="Drively: Learning to drive made easy"
        canonical="/contact"
        description="Find the perfect driving school near you"
      />
      <div className="container">
        <div className="row my-5 px-3 mb-5 pb-5">
          {/* Content and Texts */}
          <div className="col-lg-6 px-3">
            {/* Title */}
            <div className="d-md-flex d-none">
              <h5 className="contact-us_title rounded-start py-2 px-4 rounded-5 border-0 fw-medium text-white">
                CONTACT US TODAY
              </h5>
            </div>
            <div className="row d-md-none d-block">
              <SubHeading>
                <div className="fs-5 fw-bold">Contact Us Today</div>
              </SubHeading>
            </div>

            {/* Subtext */}
            <div className="row d-md-flex d-none my-2">
              <SubHeading>
                <h1 className="fw-bold">Contact our support and sales team. Get in Touch!</h1>
              </SubHeading>
            </div>
            <div className="row d-md-none d-sm-block my-2">
              <h5 className="fw-semibold text-center">
                Contact our support and sales team. <br /> Get in Touch!
              </h5>
            </div>

            {/* Body */}
            <div className="contact-us_body">
              <div className="row">
                <p className="fw-normal fs-5">
                  Have a question? concern? Request? We&apos;d love to hear from you. Connect with
                  us through the following ways:-
                </p>
              </div>
              <div className="row">
                <a
                  href="tel:+918867064863"
                  className="text-decoration-none text-reset fw-normal fs-5"
                >
                  Phone :- +91 88670 36863
                </a>
              </div>
              <div className="row">
                <a
                  href="mailto:contact@xccessor.com"
                  className="text-decoration-none text-reset fw-normal fs-5"
                >
                  Email:- contact@xccessor.com
                </a>
              </div>
            </div>

            {/* Contact Icons */}
            <div className="d-flex mt-4">
              <a
                href="tel:+918867036863"
                className="contact-us_social-icon me-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={imgPhone} alt="phone" className="img-responsive" />
              </a>
              <a
                href="https://www.instagram.com/xccessor/"
                className="contact-us_social-icon mx-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={imgInstagram} alt="instagram" className="img-responsive" />
              </a>
              <a
                href="https://wa.me/+918867036863"
                className="contact-us_social-icon mx-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={imgWhatsapp} alt="whatsapp" className="img-responsive" />
              </a>
              <a
                href="mailto:contact@xccessor.com"
                className="contact-us_social-icon mx-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={imgMail} alt="mail" className="img-responsive" />
              </a>
              <a
                href="https://www.linkedin.com/company/xccessor/"
                className="contact-us_social-icon mx-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={imgLinkedIn} alt="linkedIn" className="img-responsive" />
              </a>
            </div>
          </div>

          {/* Input Form */}
          <ContactUsLeadForm onSuccess={() => {}} />
        </div>
      </div>
    </>
  );
}

export default ContactUs;
