import React, { useEffect, useState } from 'react';
import { List } from 'react-content-loader';
import { Option } from 'react-google-places-autocomplete/build/types';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Id, toast } from 'react-toastify';

import Dependencies from 'Dependencies';
import Seo from 'Seo';
import DrivingListItem from 'pages/DrivingSchoolListing/section/DrivingListItem/driving-list-item';
import ListingNotFound from 'pages/DrivingSchoolListing/section/DrivingListingNotFound/listing-not-found';

import FooterForm from 'components/ui/FooterForm/FooterForm';
import LocationSearchForm, {
  UserLocation,
} from 'components/ui/LocationSearchForm/LocationSearchForm';
import SubHeading from 'components/ui/SubHeading';

import { ApiError } from 'domain/ApiErrorResponse';
import { DrivingSchoolModel } from 'domain/DrivingSchoolModel';

import './driving-listing.css';

function DrivingSchoolListing() {
  /**
   * Hook: Search Params from the url;
   */
  const [searchParams, setSearchParams] = useSearchParams();
  /**
   * Hook: The location hook to get data;
   */
  const location = useLocation();
  /**
   * Hook: The navigation hook;
   */
  const navigate = useNavigate();
  /**
   * State: The schools in the searched location;
   */
  const [schools, setSchools] = useState<DrivingSchoolModel[]>();
  /**
   * State: The User's searched location Name.
   */
  const [locationName, setLocationName] = useState<string>('');
  /**
   * State: The User's Lat, Long that was fetched; undefined if the location is unavailable.
   */
  const [userLocation, setUserLocation] = useState<UserLocation>();

  const [fetchingLocation, setFetchingLocation] = useState<boolean>(false);
  const [toastId, setToastId] = useState<Id | undefined>();

  useEffect(() => {
    const uLocation = location.state?.userLocation;
    setUserLocation(uLocation);
  }, [location.state?.userLocation]);

  useEffect(() => {
    let id;
    if (fetchingLocation) id = toast.info('Fetching Your Location..', { autoClose: false });
    else toast.dismiss(toastId);
    setToastId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingLocation]);

  useEffect(() => {
    async function fetch() {
      const mLocation = searchParams.get('location');
      if (mLocation && mLocation.split(',')[0]) {
        setLocationName(mLocation);
        // Since the location description contains comma separated values, we are picking the closest location

        try {
          const relevantLocation = mLocation.split(',')[0];
          const lSchools = await Dependencies.drivingSchoolRepo.getDrivingSchoolListing(
            relevantLocation
          );
          const result = lSchools
            ?.map((school) => {
              const localSchool = school;
              try {
                const splitLocation = school.attributes?.lat_long?.split(',', 2);
                if (!splitLocation || splitLocation.length !== 2) return localSchool;

                // extract the lat lng of the business
                const lat = parseFloat(splitLocation[0].trim());
                const lng = parseFloat(splitLocation[1].trim());

                // compute the distance
                if (lat && lng && userLocation?.latitude && userLocation.longitude) {
                  if (!window.google) return localSchool;
                  const computedDistance = google.maps.geometry?.spherical?.computeDistanceBetween(
                    new google.maps.LatLng(userLocation?.latitude, userLocation?.longitude),
                    new google.maps.LatLng(lat, lng)
                  );

                  if (localSchool.attributes)
                    localSchool.attributes.distance = (computedDistance / 1000).toFixed(0);
                }
              } catch (error) {
                // do nothing
              }
              return localSchool;
            })
            ?.sort((a, b) =>
              (a.attributes?.distance || (a.attributes?.drively_rating ?? 0)) <
              (b.attributes?.distance || (a.attributes?.drively_rating ?? 0))
                ? -1
                : 1
            );
          setSchools(result);
        } catch (error) {
          const apiError = error as ApiError;
          if (apiError) {
            const errorMessage = apiError?.response?.data?.error?.message;
            toast.error(errorMessage);
          } else toast.error('Oops, Something went wrong!', { autoClose: 5000 });
        }
      } else {
        toast.error('Invalid location, please try again!');
        navigate({
          pathname: '/',
        });
      }
    }
    fetch();
  }, [navigate, searchParams, userLocation?.latitude, userLocation?.longitude]);

  function handleLocationSearch(event: React.SyntheticEvent, locationData: Option | null) {
    event.preventDefault();
    const prediction = locationData?.value as google.maps.places.AutocompletePrediction;
    if (prediction) {
      setSearchParams({ location: prediction.description });
      setUserLocation(undefined);
    }
  }

  async function handleUserLocationFetch(uLocation: UserLocation) {
    if (!uLocation.latitude || !uLocation.longitude) {
      toast.error('Invalid Location Fetched!');
      return;
    }
    setUserLocation(uLocation);

    // Use Google geocoder API to get the name for the location that needs to be displayed on the UI
    try {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          location: {
            lat: uLocation.latitude,
            lng: uLocation.longitude,
          },
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results) {
            let foundLocality = false;
            results.forEach((result) => {
              if (result.types.includes('locality')) {
                foundLocality = true;
                setSearchParams({ location: result.formatted_address });
              }
            });
            if (!foundLocality)
              toast.error(
                'Oops, Kindly enter your nearest city. We could not fetch the city based on your location.',
                { autoClose: 10_000 }
              );
          }
        }
      );
    } catch (error) {
      toast.error('Oops, Something went wrong!');
    }
  }

  function handleLocateMeClick() {
    if (fetchingLocation) return;
    if (navigator.geolocation) {
      setFetchingLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          handleUserLocationFetch({ latitude, longitude });
          setFetchingLocation(false);
        },
        (error) => {
          setFetchingLocation(false);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              toast.error('User denied the request for Geolocation.');
              break;
            case error.POSITION_UNAVAILABLE:
              toast.error('Location information is unavailable.');
              break;
            case error.TIMEOUT:
              toast.error('The request to get user location timed out.');
              break;
            default:
              toast.error('An unknown error occurred.');
              break;
          }
        }
      );
    } else {
      toast.error('Geolocation is not supported by this browser');
    }
  }

  return (
    <>
      <Seo
        title="Drively: Learning to drive made easy"
        canonical="/listing"
        description="Find the perfect driving school near you"
      />
      {/* Search bar and heading */}
      <div className="container">
        <div className="d-flex flex-column-reverse flex-md-column py-5">
          <div className="row">
            <div className="col-lg-3 col-md-1 col-sm-1"> </div>
            <div className="col-lg-6 col-md-10 col-sm-10">
              <div className="justify-content-center">
                <LocationSearchForm
                  locationValue={locationName}
                  onSubmit={(e, l) => handleLocationSearch(e, l)}
                  onLocationFetched={(l) => handleUserLocationFetch(l)}
                />
                <div className="row mt-2">
                  <Link to="/">
                    <p className="learn-driving_search-subscript fs-6">
                      Or, get your own listing website as <b>Driving School / Driving Instructor</b>
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-1 col-sm-1"> </div>
          </div>
          <div className="row mt-md-4">
            <div className="col-12">
              <div>
                <SubHeading>Driving schools in {locationName}</SubHeading>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Listing Results */}
      <div className="container mb-5 align-items-center">
        {!schools && <List />}
        {schools &&
          schools.length > 0 &&
          schools.map((school) => (
            <DrivingListItem
              key={school.id}
              schoolModel={school}
              onLocateMeClicked={() => handleLocateMeClick()}
            />
          ))}
      </div>

      {/* Section to show no schools found */}
      {schools && schools.length === 0 && (
        <div className="pt-5">
          <ListingNotFound />
        </div>
      )}

      {/* Form for generating leads */}
      <div className="container-fluid driving-listing__form-container d-block d-lg-none py-5 mt-4">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <FooterForm />
          </div>
        </div>
      </div>

      {/* Container 2 Find top driving schools ends */}
    </>
  );
}

export default DrivingSchoolListing;
