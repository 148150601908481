import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Dependencies from 'Dependencies';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import formFooter from 'schemas/validations/driving-school-lead-footer';

import { ApiError } from 'domain/ApiErrorResponse';
import { DrivingSchoolModel } from 'domain/DrivingSchoolModel';
import { LearnerLeadModel, LearnerLeadType } from 'domain/LearnerLeadModel';

import './FooterForm.css';

const initialValues = {
  name: '',
  drivingSchoolName: '',
  mobileNumber: '',
};

function FooterForm() {
  const [searchResults, setSearchResults] = useState<DrivingSchoolModel[]>();
  const [dropdownVisibility, setDropdownVisibility] = useState<string>('');
  const [dropdownSelected, setDropdownSelected] = useState<boolean>(false);

  const { values, errors, handleBlur, handleChange, handleSubmit, touched, validateField } =
    useFormik({
      initialValues,
      validationSchema: formFooter,
      onSubmit: (value, action) => {
        async function createNewLearnerLead() {
          const schoolId = searchResults?.find(
            (item) => item.attributes?.name === value.drivingSchoolName
          )?.id;
          if (!schoolId) {
            values.drivingSchoolName = '';
            validateField('drivingSchoolName');
            return;
          }
          const lead: LearnerLeadModel = {
            data: {
              name: value.name ?? '',
              mobile_number: value.mobileNumber ?? '',
              type: LearnerLeadType.needs_help,
              gender: 'na',
              age: null,
              dschool_reachable: null,
              pincode: null,
              driving_school: schoolId,
              driving_school_course: null,
            },
          };
          try {
            await Dependencies.learnerLeadRepo.createNewLearnerLead(lead);
            toast.success('We will get back to you ASAP!');
            action.resetForm();
            setSearchResults([]);
          } catch (error) {
            const apiError = error as ApiError;
            if (apiError) {
              const errorMessage = apiError?.response?.data?.error?.message;
              if (
                apiError.response?.status === 400 &&
                errorMessage === 'This attribute must be unique'
              )
                toast.error('This mobile number is already registered!', { autoClose: 5000 });
              else toast.error(errorMessage);
            } else toast.error('Oops, Something went wrong!');
          }
        }
        createNewLearnerLead();
      },
    });

  const fetchBusinesses = debounce(async (input: string) => {
    try {
      const result = await Dependencies.drivingSchoolRepo.searchDrivingSchools(input);
      if (result.length > 0) {
        setSearchResults(result);
        setDropdownVisibility('show');
      } else {
        setSearchResults(undefined);
        setDropdownVisibility('hide');
      }
    } catch (e) {
      // do nothing
      setSearchResults(undefined);
    }
  });

  useEffect(() => {
    if (values.drivingSchoolName.trim().length > 2 && !dropdownSelected) {
      fetchBusinesses(values.drivingSchoolName);
    } else if (!dropdownSelected) {
      setDropdownSelected(false);
      setSearchResults(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.drivingSchoolName]);

  return (
    <div className="container bg-white rounded-4 mobile_form-background">
      <form className="row p-4" onSubmit={handleSubmit}>
        <h6 className="footer-form__heading fw-normal text-start" style={{ fontSize: '0.8rem' }}>
          If you are unable to connect to driving school, don&lsquo;t worry we will get back to you
          in 24 hrs
        </h6>
        <div className="input-group mb-3 dropdown">
          <input
            className="form-control bg-secondary bg-opacity-25"
            placeholder="Name of driving school"
            type="text"
            name="drivingSchoolName"
            style={{ fontSize: '0.75rem' }}
            value={values.drivingSchoolName}
            onChange={(e) => {
              handleChange(e);
            }}
            onBlur={handleBlur}
          />
          {(searchResults?.length ?? 0) > 0 && (
            <ul className={`mt-5 dropdown-menu ${dropdownVisibility}`}>
              {searchResults?.map((result) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                <li
                  key={result.id}
                  className="dropdown-item"
                  style={{ fontSize: '0.75rem' }}
                  onClick={() => {
                    if (!result.attributes?.name) return;
                    setDropdownSelected(true);
                    values.drivingSchoolName = result.attributes.name;
                    setDropdownVisibility('');
                  }}
                >
                  {result.attributes?.name}
                </li>
              ))}
            </ul>
          )}
          {errors.drivingSchoolName && touched.drivingSchoolName ? (
            <div className="invalid-feedback d-block" style={{ fontSize: '0.75rem' }}>
              {errors.drivingSchoolName}
            </div>
          ) : null}
          {!searchResults && values.drivingSchoolName.length > 3 ? (
            <div className="invalid-feedback d-block" style={{ fontSize: '0.75rem' }}>
              We could not find a driving school by that name.
            </div>
          ) : null}
        </div>
        <div className="input-group mb-3">
          <input
            className="form-control bg-secondary bg-opacity-25"
            style={{ fontSize: '0.75rem' }}
            placeholder="Your name"
            type="text"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.name && touched.name ? (
            <div className="invalid-feedback d-block" style={{ fontSize: '0.75rem' }}>
              {errors.name}
            </div>
          ) : null}
        </div>
        <div className="input-group mb-3">
          <input
            className="form-control bg-secondary bg-opacity-25"
            style={{ fontSize: '0.75rem' }}
            placeholder="Whatsapp number"
            type="mobile"
            name="mobileNumber"
            value={values.mobileNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.mobileNumber && touched.mobileNumber ? (
            <div className="invalid-feedback d-block" style={{ fontSize: '0.75rem' }}>
              {errors.mobileNumber}
            </div>
          ) : null}
        </div>
        <div className="input-group d-flex justify-content-center">
          <button
            type="submit"
            className="btn btn-primary fs-6 fw-normal footer-form__button rounded-3 px-4 py-2"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default FooterForm;
