import Seo from 'Seo';

import SubHeading from 'components/ui/SubHeading/';

import imgFirst from 'assets/about_us/first.svg';
// import imgGoutam from 'assets/about_us/founders/goutam.png';
// import imgKartik from 'assets/about_us/founders/kartik.png';
// import imgPratik from 'assets/about_us/founders/pratik.png';
import imgSecond from 'assets/about_us/second.svg';
import imgThird from 'assets/about_us/third.svg';

import './about-us.css';

function AboutUs() {
  return (
    <>
      <Seo
        title="Drively: Learning to drive made easy"
        canonical="/about"
        description="Find the perfect driving school near you"
      />
      {/* first Section */}
      <div className="container mt-5">
        <div className="d-flex justify-content-center justify-content-md-start">
          <div className="row w-50">
            <SubHeading>About Us</SubHeading>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6 order-last order-md-first">
            <p className="fs-4 py-4 text-center text-md-start">
              We are the next generation of driving school who are using latest information
              technology solutions to make the learning process as exceptional, unique and enjoyable
              as can be for each learner.
            </p>
          </div>
          <div className="col-12 col-md-6 align-items-center d-flex">
            <img src={imgFirst} alt="about-us" className="img-fluid p-4" />
          </div>
        </div>
      </div>

      {/* Second section */}
      <div className="container p-4">
        <div className="row border border-3 rounded-4 border-secondary about-us_container-2 justify-content-center">
          <div className="row my-md-5 my-2 mx-md-4 justify-content-center">
            <div className="d-flex justify-content-center justify-content-md-start mt-5 mt-md-3">
              <div className="row">
                <SubHeading>Why We Started?</SubHeading>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 order-last order-md-first">
                <p className="fs-4 py-4 text-center text-md-start">
                  Turning the wheel and pressing the gas is what most people think about when
                  driving. But, with the right car and proper circumstances, driving goes from
                  routine to downright exciting. We want everyone to experience this excitement with
                  right set of knowledge.
                </p>
              </div>
              <div className="col-12 col-md-6 align-items-center d-flex">
                <img src={imgSecond} alt="why we started" className=" img-fluid" />
              </div>
            </div>

            <div className="d-flex justify-content-center d-block d-md-none mt-5 mt-md-3">
              <div className="row">
                <SubHeading>Our Mission</SubHeading>
              </div>
            </div>
            <div className="row mt-0 mt-md-5">
              <div className="col-12 col-md-6 order-last">
                <div className="row d-none d-md-block">
                  <SubHeading>Our Mission</SubHeading>
                </div>
                <p className="fs-4 py-4 text-center text-md-start">
                  We believe in making learn to drive process as smooth, efficient, transparent and
                  effective as can be for all the stakeholders using the 21st century technology and
                  tools. We want to foster the best quality driving tuition and training in terms of
                  both theory and practical skills
                </p>
              </div>
              <div className="col-12 col-md-6 align-items-center d-flex">
                <img src={imgThird} alt="our mission" className=" img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Founders Section */}
      {/* <div className="container my-3">
        <div className="row">
          <SubHeading>MEET OUR FOUNDERS</SubHeading>
        </div>
        <div className="container px-md-5">
          <div className="row my-5">
            <div className="col-12 col-md-6 d-flex justify-content-md-start justify-content-center">
              <img src={imgKartik} alt="kartik" className="img-fluid" />
            </div>
            <div className="col-12 col-md-6">
              <h2 className=" text-center text-md-start">KARTIK VERMA</h2>
              <p className="text-primary text-center text-md-start">Part CEO, part filmmaker</p>
              <p className="fs-5">
                Kartik is a final year dual degree undergraduate student at IIT Kharagpur.
                Undergoing the courses of his masters in Engineering Entrepreneurship at RMSoEE, he
                took his first entrepreneurial flight with e-commerce venture Narya in his second
                year. Then Drively.
              </p>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12 col-md-6">
              <h2 className="text-center text-md-start">PRATIK KUMAR</h2>
              <p className="text-primary text-center text-md-start">
                The COO, the Designer, the cool guy
              </p>
              <p className="fs-5">
                Experienced designer with a demonstrated history of working in the industry. He is
                drawn to good aesthetics and understand the importance of data-driven decisions. His
                curiosity to design products and his inherent style of incorporating visual design
                principles in any creative work he put out, further strengthened his decision.
              </p>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-md-end justify-content-center order-first order-md-last">
              <img src={imgPratik} alt="pratik" className="img-fluid" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 d-flex justify-content-md-start justify-content-center">
              <img src={imgGoutam} alt="goutam" className="img-fluid" />
            </div>
            <div className="col-12 col-md-6">
              <h2 className="text-center text-md-start">GOUTAM CHHIKARA</h2>
              <p className="text-primary text-center text-md-start">
                The man, the myth and the CFO
              </p>
              <p className="fs-5">
                Goutam is a B.Com graduate from Delhi University. He had his first entrepreneurial
                stint with Exceedo Developers & Consultants and Exceedo Know your land, working with
                his elder brother. He is the finance and the real estate guy. He can do and think of
                things which other can’t. He is the hacker
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default AboutUs;
