import SubHeading from 'components/ui/SubHeading/';

import './ToDoSection.css';

function ToDoSection() {
  return (
    <div className="container">
      <div className="row w-75">
        <SubHeading>
          <div className="text-white"> Finally, जो की!</div>
        </SubHeading>
      </div>
      <div className="row">
        <div className="d-flex flex-row p-2">
          <div className="todo-section_text-pill me-4">
            <div className="d-flex align-items-center m-1">
              <div className="text-wrap fs-4 fw-semibold p-4">
                Aapko और customers देगा । हाँ जी, यह भी free 😱
              </div>
            </div>
          </div>
          <div className="todo-section_text-pill me-4">
            <div className="d-flex align-items-center m-1">
              <div className="text-wrap fs-4 fw-semibold p-4">finally आप अधिक कमाएंगे 🤑</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ToDoSection;
