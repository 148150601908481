import React, { useEffect, useState } from 'react';
import { List } from 'react-content-loader';
import { Option } from 'react-google-places-autocomplete/build/types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Dependencies from 'Dependencies';
import Seo from 'Seo';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import LocationSearchForm, {
  UserLocation,
} from 'components/ui/LocationSearchForm/LocationSearchForm';
import SubHeading from 'components/ui/SubHeading';
import TestimonialSlider from 'components/ui/TestimonialSlider/TestimonialSlider';

import { ApiError } from 'domain/ApiErrorResponse';
import { TestimonialModel } from 'domain/TestimonialModel';

import imgFirstOne from 'assets/learn_driving/1x.png';
import imgFirstTwo from 'assets/learn_driving/2x.png';
import imgFirstThree from 'assets/learn_driving/3x.png';
import imgFirstFour from 'assets/learn_driving/4x.png';
import imgSecondOne from 'assets/learn_driving/road1x.png';
import imgSecondTwo from 'assets/learn_driving/road2x.png';
import imgSecondThree from 'assets/learn_driving/road3x.png';
import imgSecondFour from 'assets/learn_driving/road4x.png';

import './learn-driving.css';

function LearnDriving() {
  const [testimonies, setTestimonies] = useState<TestimonialModel[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      try {
        setTestimonies(await Dependencies.testimonyRepo.getLearnersTestimonies());
      } catch (error) {
        const apiError = error as ApiError;
        if (apiError) {
          const errorMessage = apiError?.response?.data?.error?.message;
          toast.error(errorMessage);
        } else toast.error('Oops, Something went wrong!', { autoClose: 5000 });
      }
    }
    fetch();
  }, []);

  function navigateToListing(location: string, coordinates: UserLocation | undefined) {
    if (location)
      navigate(`/listing?location=${location}`, {
        state: { userLocation: coordinates },
      });
  }

  function handleLocationSearch(event: React.SyntheticEvent, locationData: Option | null) {
    event.preventDefault();
    const prediction = locationData?.value as google.maps.places.AutocompletePrediction;
    if (prediction) {
      navigateToListing(prediction.description, undefined);
    }
  }

  async function handleUserLocationFetch(location: UserLocation) {
    if (!location.latitude || !location.longitude) return;

    try {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          location: {
            lat: location.latitude,
            lng: location.longitude,
          },
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results) {
            results.forEach((result) => {
              if (result.types.includes('locality')) {
                navigateToListing(result.formatted_address, location);
              }
            });
          }
        }
      );
    } catch (error) {
      toast.error('Oops, Something went wrong!');
    }
  }

  return (
    <>
      <Seo
        title="Drively: Learning to drive made easy"
        canonical="/"
        description="Find the perfect driving school near you"
      />
      {/* Section 1 Find top driving schools starts */}
      <section id="top">
        <div className="container-fluid learn-driving_container-1 pt-md-5 pt-0">
          <div className="container py-md-5 py-0">
            <div className="row d-flex justify-content-center">
              {/* Heading and Search Form */}
              <div className="col-md-7 mt-5">
                <SubHeading>Find top driving schools </SubHeading>

                <LocationSearchForm
                  locationValue=""
                  onSubmit={(e, l) => handleLocationSearch(e, l)}
                  onLocationFetched={(l) => handleUserLocationFetch(l)}
                />

                <div className="row mt-2">
                  <Link to="/">
                    <p className="learn-driving_search-subscript fs-6">
                      Or, get your own listing website as <b>Driving School / Driving Instructor</b>
                    </p>
                  </Link>
                </div>
              </div>

              {/* Image */}
              <div className="col-md-5 d-flex justify-content-center">
                <img
                  srcSet={`${imgFirstOne} 576w, ${imgFirstTwo} 768w, ${imgFirstThree} 992w, ${imgFirstFour} 1200w`}
                  sizes="(max-width: 576px) 90px; (max-width: 768px) 400px, (max-width: 992px) 768px, 1200px"
                  alt="learn-driving"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section 1 Find top driving schools ends */}

      {/* Section 2 Road regulations starts */}
      <section id="second" className="py-5">
        <div className="container-fluid learn-driving_container-2 py-5">
          <div className="container py-5 pt-5">
            <div className="row d-flex justify-content-center">
              <div className="col-md-6 d-flex flex-column justify-content-center">
                <SubHeading>
                  Get familiar with road <br />
                  regulations
                </SubHeading>

                <p className="fw-medium fs-4 text-center text-md-start">
                  Avoiding chalans will be easier
                </p>

                <Link
                  to="/blogs"
                  className="justify-content-start d-md-flex d-none"
                  style={{ textDecoration: 'none' }}
                >
                  <button
                    type="button"
                    className="learn-driving_tertiary-button fw-medium fs-6 py-2 px-5"
                  >
                    Learn Now
                  </button>
                </Link>
              </div>
              <div className="col-md-6 d-flex justify-content-center">
                <img
                  srcSet={`${imgSecondOne} 576w, ${imgSecondTwo} 768w, ${imgSecondThree} 992w, ${imgSecondFour} 1200w`}
                  sizes="(max-width: 576px) 90px; (max-width: 768px) 400px, (max-width: 992px) 768px, 1200px"
                  alt="learn-driving"
                  className="img-fluid"
                />
              </div>
              <Link
                to="/blogs"
                className="justify-content-center d-md-none d-flex mt-5"
                style={{ textDecoration: 'none' }}
              >
                <button type="button" className="learn-driving_tertiary-button py-2 px-4">
                  Learn Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Section 2 Road regulations ends */}

      {/* Section 3 Road learners starts */}
      <section id="third" className="py-3">
        <div className="container py-5">
          {!testimonies && <List />}
          {testimonies && testimonies.length > 0 && (
            <>
              <div className="row mb-3">
                <SubHeading underline={false}>What learners say about us?</SubHeading>
              </div>
              <TestimonialSlider testimonials={testimonies} />
            </>
          )}
        </div>
      </section>

      {/* section 3 Road learners ends */}
    </>
  );
}

export default LearnDriving;
