import axios from 'axios';

import { ApiResponseMeta } from 'domain/ApiResponseMeta';
import { BlogModel } from 'domain/BlogModel';
import { BlogsTopicModel } from 'domain/BlogsTopicModel';

import BlogDataSource from 'data/datasource/BlogDataSource';

/**
 * This is the implementation of the [BlogDataSource] to get the data from source: API(Server)
 */
type BlogTopicsResponse = {
  data: BlogsTopicModel[];
  meta: ApiResponseMeta;
};
type BlogContentResponse = {
  data: BlogModel[];
  meta: ApiResponseMeta;
};
export default class BlogApiDataSource implements BlogDataSource {
  path = `blog-topics`;

  blogPath = 'blogs';

  async getBlogTopicsWithBlogs(): Promise<BlogsTopicModel[]> {
    // TODO: Enhancement! add pagination to avoid fetching all blog topics and blogs
    const query = {
      populate: {
        blogs: {
          fields: ['title', 'short_description', 'published', 'url'],
          populate: {
            thumbnail: {
              fields: ['alternativeText', 'url'],
            },
          },
        },
      },
    };
    const response = await axios.get<BlogTopicsResponse>(this.path, { params: query });
    return response.data.data;
  }

  async getBlogContent(blog_url: string): Promise<BlogModel> {
    const query = {
      fields: ['title', 'published', 'url', 'body', 'meta'],
      filters: {
        url: {
          $eqi: blog_url,
        },
      },
    };
    const response = await axios.get<BlogContentResponse>(this.blogPath, { params: query });
    if (response.data.data.length === 0) throw new Error('No Blog Found');
    return response.data.data[0];
  }
}
