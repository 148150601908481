import { BlogModel } from 'domain/BlogModel';
import { BlogsTopicModel } from 'domain/BlogsTopicModel';

import BlogDataSource from 'data/datasource/BlogDataSource';
import BlogRepository from 'data/repository/BlogRepository';

export default class BlogRepositoryImpl implements BlogRepository {
  dataSource: BlogDataSource;

  constructor(_dataSource: BlogDataSource) {
    this.dataSource = _dataSource;
  }

  getBlogTopicsWithBlogs(): Promise<BlogsTopicModel[]> {
    return this.dataSource.getBlogTopicsWithBlogs();
  }

  getBlogContent(blog_url: string): Promise<BlogModel> {
    return this.dataSource.getBlogContent(blog_url);
  }
}
