import axios from 'axios';

import { ApiResponseMeta } from 'domain/ApiResponseMeta';
import { ContactUsLeadModel } from 'domain/ContactUsLeadModel';

import ContactUsLeadDataSource from 'data/datasource/ContactUsLeadDataSource';

type ContactUsLeadResponse = {
  data: unknown;
  meta: ApiResponseMeta;
};
class ContactUsLeadApiDataSource implements ContactUsLeadDataSource {
  path = 'contact-us-leads';

  async createNewLead(lead: ContactUsLeadModel): Promise<boolean> {
    await axios.post<ContactUsLeadResponse>(this.path, lead);
    return true;
  }
}

export default ContactUsLeadApiDataSource;
