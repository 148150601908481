import React, { useEffect, useState } from 'react';
import { Code } from 'react-content-loader';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useParams } from 'react-router-dom';
import { JSX } from 'react/jsx-runtime';

import Dependencies from 'Dependencies';
import Seo from 'Seo';

import NoDataPlaceholder from 'components/ui/NoDataPlaceholder/NoDataPlaceholder';
import SubHeading from 'components/ui/SubHeading';

import { BlogModel } from 'domain/BlogModel';

function Image(
  props: JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLImageElement> &
    React.ImgHTMLAttributes<HTMLImageElement>
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <img {...props} style={{ maxWidth: '100%' }} alt="markdown-img" />;
}

function BlogContent() {
  const { id } = useParams();
  const [blogModel, setBlogModel] = useState<BlogModel | null>();

  useEffect(() => {
    async function fetchBlogContent() {
      if (!id) return;
      try {
        setBlogModel(await Dependencies.blogsRepo.getBlogContent(id));
      } catch (error) {
        setBlogModel(null);
      }
    }
    fetchBlogContent();
  }, [id]);

  return (
    <>
      <Seo
        title="Drively: Learning to drive made easy"
        canonical={`/blogs/${blogModel?.attributes?.url}`}
        description={blogModel?.attributes?.meta?.description}
        og={blogModel?.attributes?.meta?.og}
        robots={blogModel?.attributes?.meta?.robots}
        author={blogModel?.attributes?.meta?.author}
        twitter={blogModel?.attributes?.meta?.twitter}
        keywords={blogModel?.attributes?.meta?.keywords}
      />
      <div className="container p-4">
        {blogModel === undefined && <Code />}
        {blogModel === null && (
          <NoDataPlaceholder message="We could not find the Blog you were looking for" />
        )}
        {blogModel && (
          <>
            <SubHeading>{blogModel?.attributes?.title}</SubHeading>
            <ReactMarkdown
              className="row mt-3"
              components={{
                img: Image,
              }}
            >
              {blogModel?.attributes?.body || '### OOPS, The Author Forgot to add the Blog!'}
            </ReactMarkdown>
          </>
        )}
      </div>
    </>
  );
}

export default BlogContent;
